import { IListItem } from '../../interfaces/IListItem';
import { ILoadingItem } from '../../interfaces/ILoadingItem';
import { IStorage } from '../../interfaces/IStorage';
import { ITool } from '../../interfaces/ITool';

export class ListUtils {
    public static isToolItem = (item: IListItem) => {
        return Boolean((item as ITool).storageId);
    };

    public static isStorageItem = (item: IListItem) => {
        return (item as IStorage).hasChildren !== undefined;
    };

    public static isLoadingItem = (item: IListItem) => {
        return (item as ILoadingItem).isLoading !== undefined;
    };
}
