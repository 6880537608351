import { ActionButton, IconButton } from '@fluentui/react';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { IListItem } from '../../interfaces/IListItem';
import { ILoadingItem } from '../../interfaces/ILoadingItem';
import { IStorage } from '../../interfaces/IStorage';
import { ITool } from '../../interfaces/ITool';
import { DragNDrop } from '../DragNDrop';
import { Loading } from '../Loading';
import { ToolItem } from '../ToolList/ToolItem';
import { ListItem } from './ListItem';
import { ListUtils } from './ListUtils';
import { StorageItem } from './StorageItem';
import { getClassNames } from './Style';
import { WaitingItem } from './WaitingItem';

export interface IListViewProps {
    className?: string;
    items: IListItem[];
    onAddStorage(parentId: number): Promise<void>;
    onAddItem(parentId: number): Promise<void>;
    loadChildren(id: number): Promise<IListItem[]>;
    onEditItem(item: IListItem): Promise<void>;
    onRemoveItem(item: IListItem): Promise<void>;
    allowEditing?: boolean;
    allowRemoving?: boolean;
    onDrop(element: HTMLElement, dropTargetElement: HTMLElement): Promise<void>;
    onUpdateParent(props: { id: number; parentId: number; isStorage: boolean }): Promise<void>;
}

const getLoadingItem = () =>
    ({
        id: new Date().getTime(),
        name: '',
        description: '',
        image: '',
        isLoading: true
    }) as ILoadingItem;

export const ListView: React.FC<IListViewProps> = ({
    items,
    onAddStorage,
    onAddItem,
    loadChildren,
    onEditItem,
    onRemoveItem,
    allowEditing,
    allowRemoving,
    className,
    onDrop,
    onUpdateParent
}) => {
    const [currentItems, setCurrentItems] = useState(items);
    const [isLoading, setIsLoading] = useState(false);
    const root = useRef(items);
    const next = useRef(true);
    const stack = useRef<IStorage[]>([]);
    const isStackEmpty = useCallback(() => stack.current && stack.current.length === 0, [stack]);
    const classNames = useCallback(getClassNames, [])();

    const getParent = useCallback(() => {
        if (isStackEmpty()) {
            return {
                id: -1,
                name: 'Alle Garagen'
            } as IStorage;
        }
        return stack.current.at(-1) as IStorage;
    }, [isStackEmpty, stack]);

    const loadChildrenForCurrentParent = useCallback(async () => {
        const parent = getParent();
        const children = await loadChildren(parent.id);
        if (parent.id > -1) {
            parent.children = children;
        } else {
            root.current = children;
        }
        if (parent.id === getParent().id) {
            setCurrentItems(children);
        }
    }, [getParent, loadChildren]);

    const goBack = useCallback(async () => {
        next.current = false;
        if (stack.current) {
            stack.current.pop();
            if (isStackEmpty()) {
                setCurrentItems(root.current);
                return;
            }
            const currentParent = stack.current.at(-1) as IStorage;
            const children = currentParent.children;
            if (children && children.length > 0) {
                setCurrentItems(children);
            } else {
                await loadChildrenForCurrentParent();
            }
        }
    }, [root, stack, isStackEmpty, loadChildrenForCurrentParent]);

    const goToNextLevel = async (item: IStorage) => {
        next.current = true;
        stack.current.push(item);
        if (item.hasChildren) {
            if (!item.children || item.children.length === 0) {
                setCurrentItems([getLoadingItem()]);
                item.children = await loadChildren(item.id);
                if (item.id !== getParent().id) {
                    return;
                }
            }
        }
        setCurrentItems(item.children || []);
    };

    const resetSiblings = () => {
        const parent = getParent();
        if (parent.id === -1) {
            return;
        }
        const grandParent = stack.current.at(-2);
        if (grandParent) {
            grandParent.children = [];
        }
    };

    const getParentTitle = useCallback(() => {
        return getParent().name;
    }, [getParent]);

    const getParentId = useCallback(() => {
        return getParent().id;
    }, [getParent]);

    const addNewStorage = async () => {
        const parentId = getParentId();
        await onAddStorage(parentId);
        setIsLoading(true);
        await loadChildrenForCurrentParent();
        setIsLoading(false);
    };

    const addNewItem = async () => {
        const parentId = getParentId();
        await onAddItem(parentId);
        setIsLoading(true);
        await loadChildrenForCurrentParent();
        setIsLoading(false);
    };

    const editItem = async (item: IListItem) => {
        await onEditItem(item);
        setIsLoading(true);
        await loadChildrenForCurrentParent();
        setIsLoading(false);
    };

    const removeItem = async (item: IListItem) => {
        await onRemoveItem(item);
        setIsLoading(true);
        await loadChildrenForCurrentParent();
        setIsLoading(false);
    };

    const moveOneLevelUp = async (item: IListItem, isStorage = false) => {
        await onUpdateParent({ id: item.id, parentId: getParent().parentId, isStorage });
        resetSiblings();
        await loadChildrenForCurrentParent();
    };

    const renderItem = (item: IListItem) => {
        switch (true) {
            case ListUtils.isToolItem(item):
                return (
                    <ToolItem
                        key={`tool${item.id}`}
                        item={item as ITool}
                        onClick={async () => {
                            await editItem(item);
                        }}
                        onRemove={async () => {
                            removeItem(item);
                        }}
                        onMoveOneLevelUp={moveOneLevelUp}
                        isRemoveBtnVisible={Boolean(allowRemoving)}
                        isMoveUpBtnVisible={getParent().id !== -1 && item.garageId !== (item as ITool).storageId}
                    />
                );
            case ListUtils.isStorageItem(item):
                return (
                    <StorageItem
                        key={`storage${item.id}`}
                        item={item as IStorage}
                        onClick={async (item) => {
                            await goToNextLevel(item);
                        }}
                        onRemove={async (item) => {
                            await removeItem(item);
                        }}
                        onEdit={async (item) => {
                            await editItem(item);
                        }}
                        onMoveOneLevelUp={async (item) => moveOneLevelUp(item, true)}
                        isRemoveBtnVisible={Boolean(allowRemoving)}
                        isEditBtnVisible={Boolean(allowEditing)}
                        isMoveUpBtnVisible={getParent().id !== -1 && item.garageId !== (item as IStorage).parentId}
                    />
                );
            case ListUtils.isLoadingItem(item):
                return <WaitingItem key={`waitingItem${item.id}`} item={item} />;
            default:
                return <ListItem item={item} />;
        }
    };

    useEffect(() => {
        if (!items || items.length === 0) {
            loadChildrenForCurrentParent();
        } else {
            root.current = items;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={className || classNames.controlContainer}>
            <div className={classNames.navigationPanel}>
                {!isStackEmpty() && (
                    <>
                        <ActionButton
                            className={classNames.backButton}
                            iconProps={{ iconName: 'chevronLeft' }}
                            onClick={goBack}
                        >
                            Back
                        </ActionButton>
                    </>
                )}
                <span className={classNames.navigationTitle}>{getParentTitle()}</span>
                <div className={classNames.actionPanel}>
                    <IconButton iconProps={{ iconName: 'FabricNewFolder' }} onClick={addNewStorage} />
                    {getParentId() > -1 && <IconButton iconProps={{ iconName: 'Add' }} onClick={addNewItem} />}
                </div>
            </div>
            {isLoading && <Loading className={classNames.loading} />}
            <TransitionGroup
                childFactory={(child) =>
                    React.cloneElement(child, {
                        classNames: !next.current
                            ? classNames.listTransitionLeftToRight
                            : classNames.listTransitionRightToLeft,
                        timeout: 250
                    })
                }
            >
                <CSSTransition key={getParentId()} classNames={classNames.listTransitionRightToLeft} timeout={250}>
                    <DragNDrop
                        onDrop={async (e, t) => {
                            await onDrop(e, t);
                            await loadChildrenForCurrentParent();
                        }}
                    >
                        <ul className={classNames.listContainer}>{currentItems.map((item) => renderItem(item))}</ul>
                    </DragNDrop>
                </CSSTransition>
            </TransitionGroup>
        </div>
    );
};
