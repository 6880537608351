import { mergeStyleSets } from '@fluentui/react';

export const getClassNames = () =>
    mergeStyleSets({
        imageContainer: [
            'confirm-image-container',
            {
                width: 250,
                height: 170,
                border: '1px solid silver',
                borderRadius: 5,
                margin: '24px auto',
                padding: 5
            }
        ],
        image: [
            'confirm-image',
            {
                width: 250,
                height: 170,
                objectFit: 'cover'
            }
        ]
    });
