import { IconButton, MessageBar, MessageBarType } from '@fluentui/react';
import { getClassNames } from './alert.style';

export interface IAlert {
    error: string;
    onClose(): void;
}

export const Alert: React.FC<IAlert> = ({ error, onClose }) => {
    const onCloseError = () => {
        onClose();
    };

    const classNames = getClassNames();

    return (
        <div className={classNames.alertContainer}>
            <IconButton className={classNames.closeButton} iconProps={{ iconName: 'Cancel' }} onClick={onCloseError} />
            <MessageBar
                styles={{ innerText: { marginRight: 40, textAlign: 'left' } }}
                messageBarType={MessageBarType.error}
            >
                {error}
            </MessageBar>
        </div>
    );
};
