import React, { useEffect, useState } from 'react';
import { Types } from '../../config/Types';
import { Login } from '../../pages/Login';
import { Register } from '../../pages/Register';
import { ResetPassword } from '../../pages/ResetPassword';
import { Container } from '../../services/Container';
import { UserService } from '../../services/UserService';
import { Loading } from '../Loading';

export interface IAuthenticationProps {
    render(): JSX.Element | null;
}

const enum authModes {
    Login,
    Register,
    ResetPassword
}

export const Authentication: React.FC<IAuthenticationProps> = ({ render }) => {
    const userService = Container.get(Types.userService) as UserService;
    const [isAuthenticated, setIsAuthenticated] = useState(userService.isAuthenticated);
    const [authMode, setAuthMode] = useState(authModes.Login);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        userService
            .verify()
            .then(() => {
                setIsLoading(false);
            })
            .catch((reason) => {
                setIsLoading(false);
            });
        userService.isAuthChanged.add(onAuthChanged);

        return () => {
            userService.isAuthChanged.remove(onAuthChanged);
        };
    }, [userService]);

    const onAuthChanged = (value: boolean) => {
        setIsAuthenticated(value);
    };

    const renderLogin = () => {
        switch (true) {
            case authMode === authModes.Register:
                return <Register onLogin={() => setAuthMode(authModes.Login)} />;
            case authMode === authModes.Login:
                return (
                    <Login
                        onRegister={() => setAuthMode(authModes.Register)}
                        onPasswordReset={() => setAuthMode(authModes.ResetPassword)}
                    />
                );
            case authMode === authModes.ResetPassword:
            default:
                return <ResetPassword onGoToLoginPage={() => setAuthMode(authModes.Login)} />;
        }
    };

    if (isLoading) {
        return <Loading />;
    }

    return isAuthenticated ? render() : renderLogin();
};
