import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from '@fluentui/react';
import React, { FC, useState } from 'react';
import { ILoginUser } from '../../interfaces/ILoginUser';
import { getClassNames } from './loginForm.styles';

export interface ILoginForm {
    error?: string;
    onSwitchToRegister(): void;
    onSwitchToPasswordReset(): void;
    onLogin(user: ILoginUser): void;
}

export const LoginForm: FC<ILoginForm> = ({ error, onSwitchToRegister, onSwitchToPasswordReset, onLogin }) => {
    const classNames = getClassNames();
    const [user, setUser] = useState<ILoginUser>({
        email: '',
        password: ''
    });

    const onChanged = (property: string, newValue: string) => {
        setUser({
            ...user,
            [property]: newValue
        });
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onLogin(user);
    };

    const onForgotPasswordClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        onSwitchToPasswordReset();
    };

    return (
        <form className={classNames.container} onSubmit={onSubmit}>
            <h2>Login</h2>
            <TextField
                name="email"
                className={classNames.formControl}
                placeholder="Username"
                value={user.email}
                onChange={(e, newValue) => onChanged(e.currentTarget.name, newValue || '')}
                autoComplete="username"
                required={true}
            />
            <TextField
                name="password"
                className={classNames.formControl}
                placeholder="Password"
                value={user.password}
                onChange={(e, newValue) => onChanged(e.currentTarget.name, newValue || '')}
                type="password"
                autoComplete="current-password"
                required={true}
            />
            {error && (
                <MessageBar className={classNames.formControl} messageBarType={MessageBarType.error}>
                    {error}
                </MessageBar>
            )}
            <Stack horizontal={true} horizontalAlign="end">
                <DefaultButton className={classNames.formButton} text="Register" onClick={onSwitchToRegister} />
                <PrimaryButton type="submit" className={classNames.formButton} text="Login" />
            </Stack>
            <div className={classNames.linkPanel}>
                <a href="#!" className={classNames.forgotPasswortLink} onClick={onForgotPasswordClick}>
                    Passwort vergessen
                </a>
            </div>
        </form>
    );
};
