import { ITool } from '../../interfaces/ITool';
import { IAddToolModel } from './addtool.model';

export function createNewAddToolModel(storageId = -1, garageId = -1): IAddToolModel {
    return { id: -1, name: '', image: '', description: '', categories: [], storageId: storageId };
}

export function getAddToolModelFromTool(tool: ITool): IAddToolModel {
    return {
        id: tool.id,
        name: tool.name,
        image: tool.image,
        description: tool.description,
        categories: tool.categories.map((category) => category.id),
        storageId: tool.storageId || -1
    };
}
