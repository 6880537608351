import { mergeStyles } from '@fluentui/merge-styles';
import { getTheme } from '@fluentui/style-utilities';

const theme = getTheme();

export const toolItemStyle = {
    container: mergeStyles({
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start',
        margin: '0px',
        padding: '10px',
        boxSizing: 'border-box',
        borderBottom: `1px solid ${theme.palette.neutralTertiaryAlt}`,
        cursor: 'pointer',
        textAlign: 'left',
        '&:not(:last-child)': {
            marginBottom: '15px'
        },
        '&:hover': {
            background: theme.palette.neutralLight
        },
        transition: 'background .25s ease'
    }),
    iconContainer: mergeStyles({
        flex: '0 0 auto',
        width: '73px',
        height: '73px',
        border: `1px solid ${theme.palette.neutralTertiaryAlt}`,
        padding: '5px',
        marginRight: '15px'
    }),
    icon: mergeStyles({
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    }),
    infoContainer: mergeStyles({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        marginRight: 25,
        flex: '1 1 auto',
        width: 20
    }),
    nameContainer: mergeStyles({
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }),
    actionButtonPanel: mergeStyles({
        position: 'absolute',
        bottom: 10,
        right: 10
    })
};

export const toolListStyle = {
    container: mergeStyles({
        listStyle: 'none',
        margin: '0px',
        padding: '0px'
    })
};
