import React from 'react';
import { ITool } from '../../interfaces/ITool';
import { ToolItem } from './ToolItem';
import { toolListStyle } from './style';

export interface IToolList {
    items: ITool[];
    onItemClick(item: ITool): void;
    onRemoveItemClick?(itemToRemove: ITool): void;
}

export const ToolList: React.FC<IToolList> = ({ items, onItemClick, onRemoveItemClick }) => {
    return (
        <ul className={toolListStyle.container}>
            {items.map((item) => (
                <ToolItem
                    key={item.id}
                    item={item}
                    onClick={() => onItemClick(item)}
                    onRemove={() => {
                        if (onRemoveItemClick) {
                            onRemoveItemClick(item);
                        }
                    }}
                    isRemoveBtnVisible={Boolean(onRemoveItemClick)}
                />
            ))}
        </ul>
    );
};
