import { ILoginUser } from '../interfaces/ILoginUser';
import { IRegisterUser } from '../interfaces/IRegisterUser';
import { ITokenObject } from '../interfaces/ITokenObject';
import $axios from './Client';

export class AuthClient {
    public constructor(private axios = $axios) {}

    public async register(user: IRegisterUser) {
        const response = await this.axios.post('/auth/register', user);
        return response.data;
    }

    public async requestResetPassword(email: string) {
        const response = await this.axios.post('/auth/request-reset-password', { email });
        return response.data;
    }
    public async resetPassword(tokenObject: ITokenObject) {
        const response = await this.axios.post('/auth/reset-password', {
            token: tokenObject.token,
            password: tokenObject.newPassword
        });
        return response.data;
    }

    public async login(user: ILoginUser) {
        const response = await this.axios.post('/auth/login', user);
        return response.data;
    }

    public async verify() {
        try {
            const response = await this.axios.post('/auth/verify');
            return Boolean(response.data);
        } catch (error) {
            return false;
        }
    }
}
