import { mergeStyleSets } from '@fluentui/react';

export const getClassNames = () =>
    mergeStyleSets({
        breadCrumbsContainer: [
            'bread-crumbs-container',
            {
                display: 'flex',
                margin: 0,
                padding: 0,
                listStyle: 'none',
                flexWrap: 'wrap'
            }
        ],
        breadCrumbItem: [
            'bread-crumb-item',
            {
                marginRight: 5,
                cursor: 'pointer',
                selectors: {
                    ':not(:last-child):after': {
                        content: '">"',
                        marginLeft: 5
                    }
                },
                '&.active': {
                    fontWeight: 'bold',
                    textDecoration: 'underline'
                },
                ':hover': {
                    fontWeight: 'bold'
                }
            }
        ]
    });
