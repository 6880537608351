import { mergeStyles } from '@fluentui/react';
import { ListView } from '../../components/ListView';
import { IListItem } from '../../interfaces/IListItem';
import { IStorage } from '../../interfaces/IStorage';
import { ITool } from '../../interfaces/ITool';

export const TestPage = () => {
    const storage = {
        id: 4,
        name: 'My cool box',
        description: 'My super box for screws',
        image: '1683194850508IMG_1710.webp',
        children: [],
        hasChildren: true,
        ownerId: 1,
        parentId: 1,
        garageId: 1,
        tools: []
    } as IStorage;
    const items: IListItem[] = [
        {
            id: 1,
            name: 'A super tool',
            description: 'A super tool is great',
            image: '1683194850508IMG_1710.webp',
            categories: [{ id: 1, name: 'Tools', description: 'Tools category', tools: [] }],
            storageId: 1
        } as ITool,
        {
            id: 2,
            name: 'Hammer',
            description: 'A hammer tool is great',
            image: '1683194850508IMG_1710.webp',
            categories: [{ id: 1, name: 'Tools', description: 'Tools category', tools: [] }],
            storageId: 1
        } as ITool,
        {
            id: 3,
            name: 'My super box',
            description: 'My super box for screws',
            image: '1683194850508IMG_1710.webp',
            children: [storage],
            hasChildren: true,
            ownerId: 1,
            parentId: 1,
            garageId: 1,
            tools: []
        } as IStorage,
        {
            id: 5,
            name: 'A super tool',
            description: 'A super tool is great',
            image: '1683194850508IMG_1710.webp',
            categories: [{ id: 1, name: 'Tools', description: 'Tools category', tools: [] }],
            storageId: 1
        } as ITool,
        {
            id: 6,
            name: 'Hammer',
            description: 'A hammer tool is great',
            image: '1683194850508IMG_1710.webp',
            categories: [{ id: 1, name: 'Tools', description: 'Tools category', tools: [] }],
            storageId: 1
        } as ITool,
        {
            id: 7,
            name: 'My super box',
            description: 'My super box for screws',
            image: '1683194850508IMG_1710.webp',
            children: [],
            hasChildren: false,
            ownerId: 1,
            parentId: 1,
            garageId: 1,
            tools: []
        } as IStorage
    ];

    const testContainer = mergeStyles({
        marginTop: 60,
        textAlign: 'left'
    });

    const myItems = [] as IListItem[];

    return (
        <div className={testContainer}>
            <ListView
                onDrop={async (e, t) => {
                    console.log(e, t);
                }}
                onUpdateParent={async (props) => {
                    console.log(props);
                }}
                items={myItems}
                onAddStorage={async (id) => {
                    console.log('Add storage into parent with id:', id);
                }}
                onAddItem={async (id) => {
                    console.log('Add new tool into parent with id:', id);
                }}
                onEditItem={async (item) => {
                    console.log('Edit item: ', item.name);
                }}
                onRemoveItem={async (item) => {
                    console.log('Remove item: ', item.name);
                }}
                loadChildren={(id) => {
                    return new Promise((resolve) => {
                        if (id === -1) {
                            return resolve(items);
                        }
                        setTimeout(() => {
                            resolve([{ ...storage, id: new Date().getTime(), parentId: id, children: [] } as IStorage]);
                        }, 3000);
                    });
                }}
            />
        </div>
    );
};
