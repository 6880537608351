import { Children, FC, ReactElement, cloneElement, useContext, useRef } from 'react';
import { DraggableContext } from '.';

export interface IDragHandle {
    children?: React.ReactNode;
}

export const DragTouchHandle: FC<IDragHandle> = ({ children }) => {
    const ref = useRef<HTMLElement>();
    const manager = useContext(DraggableContext);

    const onTouchStart = (ev: TouchEvent) => {
        manager.onTouchStart(ev);
    };

    const child = Children.only(children) as ReactElement;
    return cloneElement(child, {
        ref,
        onTouchStart,
        style: { userSelect: 'none', touchAction: 'none' }
    });
};
