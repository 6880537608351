import { mergeStyleSets } from '@fluentui/react';

export const getClassNames = () =>
    mergeStyleSets({
        container: [
            'loading-container',
            {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100vh',
                opacity: 0.75,
                zIndex: 999
            }
        ]
    });
