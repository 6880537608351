export class Utils {
    public static getBackendUrl() {
        return `${process.env.REACT_APP_BACK_PROTOCOL}://${process.env.REACT_APP_BACK_HOST}:${process.env.REACT_APP_BACK_PORT}/`;
    }

    public static getImageUrl() {
        return `${this.getBackendUrl()}static/`;
    }

    public static getImage200Url() {
        return `${this.getBackendUrl()}static/200/`;
    }

    public static getImage300Url() {
        return `${this.getBackendUrl()}static/300/`;
    }
}
