import axios, { AxiosRequestConfig } from 'axios';
import { AuthStorage } from '../Storage/AuthStorage';
import { Types } from '../config/Types';
import { Container } from '../services/Container';
import { Utils } from '../utils/Utils';

const backendUrl = Utils.getBackendUrl();

const $axios = axios.create({ baseURL: backendUrl });

$axios.interceptors.request.use((config) => {
    const authStorage = Container.get<AuthStorage>(Types.authStorage);
    const tokens = authStorage.get();
    if (tokens && config.headers) {
        config.headers.Authorization = `Bearer ${tokens.accessToken}`;
    }
    return config;
});

async function refreshToken(config: AxiosRequestConfig<any>) {
    const authStorage = Container.get<AuthStorage>(Types.authStorage);
    const tokens = authStorage.get();
    if (!tokens) {
        return;
    }
    const axiosInstance = axios.create({ baseURL: backendUrl });
    const response = await axiosInstance.post('/auth/refresh', {
        value: tokens.refreshToken
    });
    authStorage.set(response.data);
    if (config.headers) {
        config.headers.Authorization = `Bearer ${response.data.accessToken}`;
    }
}

$axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        const originalConfig = error.config;
        if (error.response) {
            if (error.response.status === 401 && !originalConfig.retry) {
                originalConfig.retry = true;
                await refreshToken(originalConfig);
                return axios(originalConfig);
            }
        }
        throw error;
    }
);

export default $axios;
