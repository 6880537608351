import { mergeStyleSets } from '@fluentui/merge-styles';

export const getClassNames = () =>
    mergeStyleSets({
        container: [
            'container',
            {
                maxWidth: 500,
                minWidth: 270
            }
        ],
        formControl: [
            'form-control',
            {
                ':not(:last-child)': {
                    marginBottom: 10
                }
            }
        ],
        formButton: [
            'form-button',
            {
                ':not(:last-child)': {
                    marginRight: 10
                }
            }
        ]
    });
