import { mergeStyleSets } from '@fluentui/merge-styles';
import { getTheme } from '@fluentui/react';

export const getClassNames = (theme = getTheme()) =>
    mergeStyleSets({
        container: [
            'container',
            {
                maxWidth: 500,
                minWidth: 270
            }
        ],
        formControl: [
            'form-control',
            {
                ':not(:last-child)': {
                    marginBottom: 10
                }
            }
        ],
        formButton: [
            'form-button',
            {
                ':not(:last-child)': {
                    marginRight: 10
                }
            }
        ],
        forgotPasswortLink: [
            'forgot-pw-link',
            {
                color: theme.palette.themePrimary,
                textDecoration: 'none'
            }
        ],
        linkPanel: [
            'link-panel',
            {
                textAlign: 'left'
            }
        ]
    });
