import { getTheme, mergeStyleSets, mergeStyles } from '@fluentui/react';

const theme = getTheme();

export const getClassNames = () => ({
    controlContainer: mergeStyles({
        position: 'relative',
        overflowX: 'hidden',
        minHeight: 'calc(100vh - 120px)'
    }),
    listContainer: mergeStyles({
        position: 'absolute',
        left: 0,
        right: 0,
        margin: 0,
        padding: 0,
        listStyle: 'none'
    }),
    actionPanel: mergeStyles({
        position: 'absolute',
        right: 10
    }),
    navigationPanel: mergeStyles({
        position: 'sticky',
        top: 0,
        zIndex: 1,
        height: 40,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.neutralLighter
    }),
    backButton: mergeStyles({
        position: 'absolute',
        left: 10
    }),
    navigationTitle: mergeStyles({
        width: 0,
        flex: '1 1 0',
        marginLeft: 100,
        marginRight: 100,
        fontSize: 16,
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '@media(max-width: 459px)': {
            fontSize: 14
        }
    }),
    listTransitionLeftToRight: mergeStyles({
        '&-enter, &-appear': {
            transform: 'translate3d(-100%,0,0)',
            '&-enter-active, &-appear-active': {
                transform: 'translate3d(0,0,0)',
                transition: 'transform .25s cubic-bezier(.04,.51,0,1.01)',
                willChange: 'transform'
            }
        },
        '&-exit': {
            transform: 'translate3d(0,0,0)',
            '&-exit-active': {
                transform: 'translate3d(100%,0,0)',
                transition: 'transform .25s cubic-bezier(.04,.51,0,1.01)',
                willChange: 'transform'
            }
        }
    }),
    listTransitionRightToLeft: mergeStyles({
        '&-enter, &-appear': {
            transform: 'translate3d(100%,0,0)',
            '&-enter-active, &-appear-active': {
                transform: 'translate3d(0,0,0)',
                transition: 'transform .25s cubic-bezier(.04,.51,0,1.01)',
                willChange: 'transform'
            }
        },
        '&-exit': {
            transform: 'translate3d(0,0,0)',
            '&-exit-active': {
                transform: 'translate3d(-100%,0,0)',
                transition: 'transform .25s cubic-bezier(.04,.51,0,1.01)',
                willChange: 'transform'
            }
        }
    }),
    loading: mergeStyles({
        position: 'absolute',
        top: 40,
        left: 0,
        bottom: 0,
        background: theme.palette.black,
        opacity: 0.5
    })
});

export const storageItemStyle = {
    container: mergeStyles({
        position: 'relative',
        display: 'flex',
        justifyContent: 'flex-start',
        margin: '0px',
        padding: '10px',
        boxSizing: 'border-box',
        borderBottom: `1px solid ${theme.palette.neutralTertiaryAlt}`,
        cursor: 'pointer',
        textAlign: 'left',
        '&:not(:last-child)': {
            marginBottom: '15px'
        },
        '&:hover': {
            background: theme.palette.neutralLight
        },
        transition: 'background .25s ease'
    }),
    chevronRight: mergeStyles({
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        top: 0,
        right: 5,
        bottom: 0
    }),
    iconContainer: mergeStyles({
        flex: '0 0 auto',
        width: '73px',
        height: '73px',
        border: `1px solid ${theme.palette.neutralTertiaryAlt}`,
        padding: '5px',
        marginRight: '15px'
    }),
    icon: mergeStyles({
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    }),
    infoContainer: mergeStyles({
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flex: '1 1 auto',
        marginRight: 25,
        width: 20
    }),
    nameContainer: mergeStyles({
        width: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }),
    actionButtonPanel: mergeStyles({
        position: 'absolute',
        bottom: 10,
        right: 40
    })
};

export const getWaitItemClassNames = () =>
    mergeStyleSets({
        container: mergeStyles({
            height: 75,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        })
    });
