import React, { ReactElement } from 'react';
import { DraggableManager } from './DraggableManager';
import { DummyDraggableContext } from './DummyDraggableContext';
import { IDraggableContext } from './IDraggableContext';

export interface IDragNDrop {
    children?: React.ReactNode;
    onDrop(element: HTMLElement, dropTarget: HTMLElement): void;
}

export const DraggableContext = React.createContext<IDraggableContext>(new DummyDraggableContext());

export class DragNDrop extends React.PureComponent<IDragNDrop> {
    private manager: IDraggableContext;
    public constructor(props: IDragNDrop) {
        super(props);
        this.manager = new DraggableManager({ onDrop: props.onDrop });
    }

    public render(): React.ReactNode {
        const child = React.Children.only(this.props.children) as ReactElement;
        return (
            <DraggableContext.Provider value={this.manager}>
                {React.cloneElement(child, {
                    style: { position: 'absoute' }
                })}
            </DraggableContext.Provider>
        );
    }
}
