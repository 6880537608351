import { FontWeights, getTheme, IconButton, ITextStyles, Text } from '@fluentui/react';
import React, { useRef, useState } from 'react';
import { StorageClient } from '../../api/StorageClient';
import { ToolsClient } from '../../api/ToolsClient';
import { AddBagForm } from '../../components/AddBagForm';
import { createNewAddStorageModel } from '../../components/AddBagForm/addstorage.factory';
import { IAddStorageModel } from '../../components/AddBagForm/addstorage.model';
import { AddToolForm } from '../../components/AddToolForm';
import { getAddToolModelFromTool } from '../../components/AddToolForm/addtool.factory';
import { Alert } from '../../components/Alert';
import { Confirm } from '../../components/Confirm';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { ListView } from '../../components/ListView';
import { ListUtils } from '../../components/ListView/ListUtils';
import { Waiting } from '../../components/Waiting';
import { Types } from '../../config/Types';
import { IStorage } from '../../interfaces/IStorage';
import { ITool } from '../../interfaces/ITool';
import { Container } from '../../services/Container';
import { ToolsService } from '../../services/ToolsService';
import { ErrorHelper } from '../../utils/ErrorHelper';
import { getClassNames } from './settings.style';

export interface ISettingsProps {
    setNavigationPath(path: string): void;
}

export const Settings: React.FC<ISettingsProps> = ({ setNavigationPath }) => {
    const classNames = getClassNames();
    const theme = getTheme();
    const boldStyle: Partial<ITextStyles> = {
        root: {
            fontWeight: FontWeights.semibold,
            color: theme.palette.white
        }
    };
    const toolsService = Container.get<ToolsService>(Types.toolsService);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<Record<string, string>>({});
    const [errorMsg, setErrorMsg] = useState('');
    const [isAddBagFormOpened, setIsAddBagFormOpened] = useState(false);
    const [isAddToolFormOpened, setIsAddToolFormOpened] = useState(false);

    const parentId = useRef<number>(-10);
    const storageToUpdate = useRef<IAddStorageModel>();
    const toolToUpdate = useRef<ITool>();
    const confirm = useRef<Confirm>(null);
    const waitForUpdate = useRef<Promise<void>>();
    const resolver = useRef<{ resolve: () => void; reject: (error: Error) => void }>();

    const createWaitForUpdate = () => {
        waitForUpdate.current = new Promise((resolve, reject) => {
            resolver.current = { resolve, reject };
        });
    };

    const onCloseAddBagForm = () => {
        storageToUpdate.current = undefined;
        setIsAddBagFormOpened(false);
    };

    const onCloseAddToolForm = () => {
        setIsAddToolFormOpened(false);
    };

    const openAddBagForm = (parentId: number) => {
        setError({});
        storageToUpdate.current = createNewAddStorageModel(parentId);
        setIsAddBagFormOpened(true);
    };

    const openAddToolForm = async (storageId: number) => {
        setError({});
        parentId.current = storageId;
        toolToUpdate.current = undefined;
        setIsAddToolFormOpened(true);
    };

    const removeToolFromDb = async (item: ITool) => {
        try {
            await toolsService.removeTool(item);
            resolver.current?.resolve();
        } catch (error) {
            setErrorMsg((error as Error).message);
            resolver.current?.reject(error as Error);
        }
    };

    const removeTool = async (item: ITool) => {
        if (confirm.current) {
            confirm.current.areYouSure({
                headerText: `${item.name} - entfernen?`,
                bodyText: `Bist du sicher, dass du '${item.name}' unwiderruflich entfernen möchtest?`,
                imageSrc: item.image,
                onOk: async () => {
                    await removeToolFromDb(item);
                },
                onCancel: async () => {
                    resolver.current?.resolve();
                }
            });
        }
    };

    const removeStorage = async (storage: IStorage) => {
        if (confirm.current) {
            if (storage.hasChildren) {
                setErrorMsg('Nur leere Fächer können gelöscht werden');
                return;
            }

            confirm.current.areYouSure({
                headerText: `${storage.name} - entfernen?`,
                bodyText: `Bist du sicher, dass du '${storage.name}' unwiderruflich entfernen möchtest?`,
                imageSrc: storage.image,
                onOk: async () => {
                    const storageClient = Container.get<StorageClient>(Types.storageClient);
                    await storageClient.removeStorage(storage.id);
                    resolver.current?.resolve();
                },
                onCancel: async () => {
                    resolver.current?.reject(new Error(`Removing of storage: '${storage}' was canceled by the user`));
                }
            });
        }
    };

    const onAddStorage = async (data: FormData) => {
        try {
            setError({});
            setIsLoading(true);
            const storageClient = Container.get<StorageClient>(Types.storageClient);
            await storageClient.createStorage(data);
            onCloseAddBagForm();
            setIsLoading(false);
            resolver.current?.resolve();
            return true;
        } catch (error) {
            setIsLoading(false);
            setError(ErrorHelper.getErrorForControl(error as Error));
            resolver.current?.reject(error as Error);
        }
        return false;
    };

    const onUpdateStorage = async (data: FormData) => {
        try {
            setError({});
            setIsLoading(true);
            const storageClient = Container.get<StorageClient>(Types.storageClient);
            await storageClient.updateStorage(data);
            setIsLoading(false);
            resolver.current?.resolve();
            return true;
        } catch (error) {
            setIsLoading(false);
            setError(ErrorHelper.getErrorForControl(error as Error));
            resolver.current?.reject(error as Error);
        }
        return false;
    };

    const onSaveTool = async (data: FormData) => {
        try {
            setError({});
            setIsLoading(true);
            await toolsService.addTool(data);
            setIsLoading(false);
            resolver.current?.resolve();
            return true;
        } catch (error) {
            setIsLoading(false);
            setError(ErrorHelper.getErrorForControl(error as Error));
            resolver.current?.reject(error as Error);
        }
        return false;
    };

    const onUpdateTool = async (data: FormData) => {
        try {
            setError({});
            setIsLoading(true);
            await toolsService.updateTool(data);
            setIsLoading(false);
            resolver.current?.resolve();
            return true;
        } catch (error) {
            setIsLoading(false);
            setError(ErrorHelper.getErrorForControl(error as Error));
            resolver.current?.reject(error as Error);
        }
        return false;
    };

    const onCloseAlert = () => {
        setErrorMsg('');
    };

    const convertToNumberAndCheck = (id: unknown, parentId: unknown) => {
        const numId = Number(id);
        const numParentId = Number(parentId);
        if (isNaN(numId) || numId < 0) {
            throw new Error('The id of draggable element is not set');
        }

        if (isNaN(numParentId) || numParentId < 0) {
            throw new Error('The id of dropable element is not set');
        }

        return { id: numId, parentId: numParentId };
    };

    const onDrop = async (element: HTMLElement, dropTarget: HTMLElement) => {
        const data = convertToNumberAndCheck(element.dataset.id, dropTarget.dataset.id);
        await onUpdateParent({ ...data, isStorage: Boolean(element.dataset.isstorage) });
    };

    const onUpdateParent = async ({
        id,
        parentId,
        isStorage
    }: {
        id: number;
        parentId: number;
        isStorage: boolean;
    }) => {
        const client = isStorage
            ? Container.get<StorageClient>(Types.storageClient)
            : Container.get<ToolsClient>(Types.toolsClient);
        await client.updateParent(id, parentId);
    };

    return (
        <>
            <Header>
                <Text variant="large" styles={boldStyle}>
                    myGarage
                </Text>
                <Text variant="mediumPlus" styles={boldStyle}>
                    Verwaltung der Werkzeuge/Teile
                </Text>
            </Header>
            {errorMsg && <Alert error={errorMsg} onClose={onCloseAlert} />}
            <div className={classNames.container}>
                <ListView
                    className={classNames.listContainer}
                    allowRemoving={true}
                    allowEditing={true}
                    items={[]}
                    onDrop={onDrop}
                    onUpdateParent={onUpdateParent}
                    onAddStorage={async (id) => {
                        createWaitForUpdate();
                        openAddBagForm(id);
                        return waitForUpdate.current as Promise<void>;
                    }}
                    onAddItem={async (id) => {
                        if (id < 0) {
                            setErrorMsg('Hier können nur neue Garagen erstellt/hinzugefügt werden!');
                            return;
                        }
                        createWaitForUpdate();
                        await openAddToolForm(id);
                        return waitForUpdate.current as Promise<void>;
                    }}
                    onEditItem={async (item) => {
                        if (ListUtils.isToolItem(item)) {
                            createWaitForUpdate();
                            parentId.current = (item as ITool).storageId as number;
                            toolToUpdate.current = item as ITool;
                            setError({});
                            setIsAddToolFormOpened(true);
                            return waitForUpdate.current as Promise<void>;
                        }
                        if (ListUtils.isStorageItem(item)) {
                            createWaitForUpdate();
                            storageToUpdate.current = item as IStorage;
                            setError({});
                            setIsAddBagFormOpened(true);
                            return waitForUpdate.current as Promise<void>;
                        }
                        return Promise.resolve();
                    }}
                    onRemoveItem={async (item) => {
                        if (ListUtils.isToolItem(item)) {
                            createWaitForUpdate();
                            await removeTool(item as ITool);
                            return waitForUpdate.current as Promise<void>;
                        }
                        if (ListUtils.isStorageItem(item)) {
                            createWaitForUpdate();
                            await removeStorage(item as IStorage);
                            return waitForUpdate.current as Promise<void>;
                        }
                    }}
                    loadChildren={(id) => toolsService.loadItems(id)}
                />
            </div>
            <Footer>
                myGarage
                <div className="action-panel">
                    <IconButton
                        styles={{
                            root: {
                                color: theme.palette.white
                            },
                            rootHovered: {
                                color: theme.palette.themePrimary
                            }
                        }}
                        iconProps={{
                            iconName: 'Home'
                        }}
                        onClick={() => setNavigationPath('/main')}
                    />
                </div>
            </Footer>
            {storageToUpdate.current && (
                <AddBagForm
                    bagToUpdate={storageToUpdate.current}
                    error={error}
                    onAdd={onAddStorage}
                    onUpdate={onUpdateStorage}
                    onClose={onCloseAddBagForm}
                    showDialog={isAddBagFormOpened}
                />
            )}
            <AddToolForm
                showForm={isAddToolFormOpened}
                toolToUpdate={toolToUpdate.current && getAddToolModelFromTool(toolToUpdate.current)}
                error={error}
                onClose={onCloseAddToolForm}
                onSaveTool={onSaveTool}
                onUpdateTool={onUpdateTool}
                parentId={parentId.current}
            />
            <Confirm ref={confirm} />
            <Waiting isLoading={isLoading} />
        </>
    );
};
