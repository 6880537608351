import { getClonedItemClassNames } from './Style';
import { Point } from './Types/Point';

export class DragItem {
    private _isDragging = false;
    private startPoint: Point;
    private currentPoint: Point;
    private htmlElement: HTMLElement | null;
    private clonedElement: HTMLElement | null;
    private dragOverElement: HTMLElement | null;

    constructor() {
        this.isDragging = false;
        this.startPoint = { x: 0, y: 0 };
        this.currentPoint = { x: 0, y: 0 };
        this.htmlElement = null;
        this.clonedElement = null;
        this.dragOverElement = null;
    }

    get isDragging() {
        return this._isDragging;
    }

    set isDragging(value: boolean) {
        this._isDragging = value;
    }

    getXMovement(): number {
        return this.currentPoint.x - this.startPoint.x;
    }

    getYMovement(): number {
        return this.currentPoint.y - this.startPoint.y;
    }

    setHtmlElement(value: HTMLElement | null) {
        this.htmlElement = value;
    }

    getHtmlElement() {
        return this.htmlElement as HTMLElement;
    }

    setClonedElement(value: HTMLElement | null) {
        this.clonedElement = value;
    }

    getDragOverElement() {
        return this.dragOverElement as HTMLElement;
    }

    setDragOverElement(value: HTMLElement | null) {
        this.dragOverElement = value;
    }

    setStartPoint(position: Point) {
        this.startPoint = position;
    }

    setStartPointWithScrollOffset(x: number, y: number) {
        this.startPoint = { x, y: y + this.getScrollOffset().y };
    }

    setCurrentPoint(position: Point) {
        this.currentPoint = position;
    }

    setCurrentPointWithScrollOffset(x: number, y: number) {
        this.currentPoint = { x, y: y + this.getScrollOffset().y };
    }

    isElementTheSame(element: HTMLElement) {
        return element === this.htmlElement;
    }

    isDragOverElementTheSame(element: HTMLElement) {
        return element === this.dragOverElement;
    }

    getElementTagName() {
        return (this.htmlElement?.tagName as string) ?? '';
    }

    startDrag() {
        this.isDragging = true;
        this.clonedElement = this.cloneElement();
    }

    clear() {
        this.htmlElement = null;
        this.clonedElement?.remove();
        this.clonedElement = null;
        this.dragOverElement = null;
        this.isDragging = false;
    }

    getParent() {
        return this.htmlElement?.parentElement as HTMLElement;
    }

    translateClonedElement() {
        const transform = `translate3d(${this.getXMovement()}px, ${this.getYMovement()}px, 0)`;
        if (this.clonedElement) {
            this.clonedElement.style.transform = transform;
        }
    }

    autoScroll(position: Point) {
        if (!this.isDragging) {
            return;
        }
        const scrollParent = this.getScrollParent();
        const rect = scrollParent.getBoundingClientRect();
        const offsetY = position.y - rect.top;

        if (offsetY < 60) {
            scrollParent.scrollTop -= 5; // Scroll up
        } else if (offsetY > rect.height - 50) {
            scrollParent.scrollTop += 5; // Scroll down
        }
    }

    private hasOverflow(element: HTMLElement) {
        return (getComputedStyle(element).overflow || '').includes('auto');
    }

    private getScrollParent() {
        let scrollParent = this.htmlElement?.parentElement as HTMLElement;

        while (scrollParent && !this.hasOverflow(scrollParent)) {
            scrollParent = scrollParent.parentElement as HTMLElement;
        }
        return scrollParent ?? document.scrollingElement;
    }

    private getScrollOffset() {
        return { x: this.getScrollParent().scrollLeft, y: this.getScrollParent().scrollTop };
    }

    private cloneElement() {
        const classNames = getClonedItemClassNames();
        const clonedElement = this.htmlElement?.cloneNode(true) as HTMLElement;
        clonedElement.id = 'clonedElement';
        clonedElement.style.zIndex = '9999';
        clonedElement.style.position = 'absolute';
        clonedElement.style.left = `${this.htmlElement?.offsetLeft || 0 + 10}px`;
        clonedElement.style.top = `${this.htmlElement?.offsetTop}px`;
        clonedElement.style.width = `${this.htmlElement?.offsetWidth}px`;
        clonedElement.classList.add(classNames.draggableItem);
        this.getParent().appendChild(clonedElement);
        return clonedElement;
    }
}
