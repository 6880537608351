export class Container {
    private static instances: { [key: symbol]: unknown } = {};
    public static get<T = unknown>(type: symbol) {
        if (type in this.instances) {
            return this.instances[type] as T;
        }
        throw new Error(`No instance could be found for type: ${type.toString()}`);
    }

    public static add(type: symbol, newInstance: unknown) {
        this.instances[type] = newInstance;
    }
}
