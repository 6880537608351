import { jwtDecode } from 'jwt-decode';
import { AuthStorage } from '../Storage/AuthStorage';
import { AuthClient } from '../api/AuthClient';
import { Types } from '../config/Types';
import { ILoginUser } from '../interfaces/ILoginUser';
import { IRegisterUser } from '../interfaces/IRegisterUser';
import { ITokenObject } from '../interfaces/ITokenObject';
import { IUserInfo } from '../interfaces/IUserInfo';
import { Container } from './Container';
import { EventHandlers } from './EventHandler';

export class UserService {
    private _isAuthenticated = false;

    public constructor(private _client = new AuthClient()) {}

    public async register(user: IRegisterUser) {
        const tokens = await this._client.register(user);
        Container.get<AuthStorage>(Types.authStorage).set(tokens);
        this.isAuthenticated = true;
    }

    public async requestResetPassword(email: string) {
        return this._client.requestResetPassword(email);
    }

    public async resetPassword(tokenObject: ITokenObject) {
        return this._client.resetPassword(tokenObject);
    }

    public async login(user: ILoginUser) {
        const tokens = await this._client.login(user);
        Container.get<AuthStorage>(Types.authStorage).set(tokens);
        this.isAuthenticated = true;
    }

    public logout() {
        const authStorage = new AuthStorage();
        authStorage.clear();
        this.isAuthenticated = false;
    }

    public async verify() {
        this.isAuthenticated = await this._client.verify();
    }

    public getUserInfo() {
        const authStorage = Container.get<AuthStorage>(Types.authStorage);
        const tokens = authStorage.get();
        if (!tokens) {
            throw new Error('Not logged in');
        }
        return jwtDecode<IUserInfo>(tokens.accessToken);
    }

    public isAuthChanged = new EventHandlers<boolean>();

    public get isAuthenticated() {
        return this._isAuthenticated;
    }

    private set isAuthenticated(value: boolean) {
        if (this._isAuthenticated === value) {
            return;
        }
        this._isAuthenticated = value;
        this.isAuthChanged.dispatch(value);
    }
}
