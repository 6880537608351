import { IconButton, Text } from '@fluentui/react';
import { AsyncImage } from 'loadable-image';
import { FC, useEffect, useState } from 'react';
import { Types } from '../../config/Types';
import { IStorage } from '../../interfaces/IStorage';
import { ITool } from '../../interfaces/ITool';
import { Container } from '../../services/Container';
import { ToolsService } from '../../services/ToolsService';
import { Utils } from '../../utils/Utils';
import { BreadCrumbs } from '../BreadCrumbs';
import { getClassNames } from './Style';

export interface IToolDeailsProps {
    close(): void;
    tool?: ITool;
}

export const ToolDetails: FC<IToolDeailsProps> = ({ close, tool }) => {
    const classNames = getClassNames();
    const [parentList, setParentList] = useState<IStorage[]>([]);
    const [activeParentIndex, setActiveParentIndex] = useState(-1);
    const toolService = Container.get<ToolsService>(Types.toolsService);

    const onBreadCrumbItemClicked = (index: number) => {
        setActiveParentIndex(index);
    };

    useEffect(() => {
        if (tool && tool.storageId) {
            toolService.getParents(tool.storageId).then((storages) => {
                setParentList(storages);
                setActiveParentIndex(storages.length - 1);
            });
        }
    }, [toolService, tool]);

    return (
        <div className={classNames.container}>
            <div className={classNames.closeBtn}>
                <IconButton iconProps={{ iconName: 'Cancel' }} onClick={close} />
            </div>
            {tool && (
                <>
                    <div className={classNames.breadCrumbs}>
                        <BreadCrumbs
                            items={parentList}
                            activeIndex={activeParentIndex}
                            onItemClicked={onBreadCrumbItemClicked}
                        />
                    </div>
                    <div className={classNames.parentRow}>
                        <div className={classNames.parentImageContainer}>
                            {activeParentIndex > -1 && (
                                <AsyncImage
                                    className={classNames.parentImage}
                                    alt="Parent"
                                    src={`${Utils.getImage300Url()}${parentList[activeParentIndex].image}`}
                                />
                            )}
                        </div>
                    </div>
                    <div>
                        <Text variant="xLargePlus">{tool.name}</Text>
                    </div>
                    <div className={classNames.detailsRow}>
                        <div className={classNames.imageContainer}>
                            <div className={classNames.image}>
                                <AsyncImage
                                    className={classNames.image}
                                    alt="Tool"
                                    src={`${Utils.getImageUrl()}${tool.image}`}
                                />
                            </div>
                        </div>
                        <div className={classNames.toolDetails}>
                            <p>{tool.description}</p>
                        </div>
                    </div>
                </>
            )}
            {!tool && <div>No tool selected</div>}
        </div>
    );
};
