import { getTheme, mergeStyleSets } from '@fluentui/style-utilities';

export const getClassNames = (theme = getTheme()) =>
    mergeStyleSets({
        header: [
            'header',
            {
                display: 'flex',
                flexShrink: 0,
                boxSizing: 'border-box',
                alignItems: 'center',
                justifyContent: 'space-between',
                background: theme.palette.themePrimary,
                width: '100%',
                height: '60px',
                padding: 'auto 15px',
                color: theme.palette.white,
                zIndex: 1
            }
        ]
    });
