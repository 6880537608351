import { mergeStyleSets } from '@fluentui/merge-styles';

export const getClassNames = () =>
    mergeStyleSets({
        container: [
            'register-container',
            {
                display: 'flex',
                justifyContent: 'center',
                flex: '1 1 auto',
                overflow: 'hidden'
            }
        ]
    });
