import { DragItem } from './DragItem';
import { IDraggableContext } from './IDraggableContext';
import { getDragOverClassName } from './Style';
import { Point } from './Types/Point';

export interface IDraggableManagerProps {
    onDrop(element: HTMLElement, dropLocation: HTMLElement): void;
}

export class DraggableManager implements IDraggableContext {
    private grabbingStyleSheetIndex: number | null = null;
    private readonly dragItem = new DragItem();
    private _wasDragging = false;

    public constructor(public props: IDraggableManagerProps) {}
    public getInfo(): string {
        return 'Manager';
    }

    public get wasDragging() {
        return this._wasDragging;
    }

    public set wasDragging(value: boolean) {
        if (this._wasDragging !== value) {
            this._wasDragging = value;
        }
    }

    public setItemHtmlElement(element: HTMLElement): void {
        this.dragItem.setHtmlElement(element);
    }

    public onMouseDown(ev: MouseEvent): void {
        ev.stopPropagation();
        const position = this.getPositionFromMouseEvent(ev);
        this.setStartAndCurrentPoint(position);
        this.initEventHandlers();
    }

    public onTouchStart(ev: TouchEvent): void {
        const position = this.getPositionFromTouchEvent(ev);
        this.setStartAndCurrentPoint(position);
        this.initTouchEventHandlers();
    }

    public addGrabbingCursor() {
        // Get the first stylesheet (you can select any existing active stylesheet)
        const sheet = window.document.styleSheets[0];

        // Insert the CSS rule
        this.grabbingStyleSheetIndex = sheet.insertRule(`* { cursor: grabbing !important; }`, sheet.cssRules.length);
    }

    public removeGrabbingCursor() {
        if (this.grabbingStyleSheetIndex !== null) {
            const sheet = window.document.styleSheets[0];
            sheet.deleteRule(this.grabbingStyleSheetIndex);
            this.grabbingStyleSheetIndex = null;
        }
    }

    private setStartAndCurrentPoint(position: Point) {
        this.wasDragging = false;
        this.dragItem.setStartPointWithScrollOffset(position.x, position.y);
        this.dragItem.setCurrentPointWithScrollOffset(position.x, position.y);
    }

    private initEventHandlers() {
        document.addEventListener('mousemove', this.onPointerMove);
        document.addEventListener('mouseup', this.onPointerUp);
    }

    private initTouchEventHandlers() {
        document.addEventListener('touchmove', this.onTouchMove);
        document.addEventListener('touchend', this.onTouchEnd);
    }

    private removeEventHandlers() {
        document.removeEventListener('mousemove', this.onPointerMove);
        document.removeEventListener('mouseup', this.onPointerUp);
    }

    private rmoveTouchEventHandlers() {
        document.removeEventListener('touchmove', this.onTouchMove);
        document.removeEventListener('touchend', this.onTouchEnd);
    }

    private onPointerMove = (ev: MouseEvent) => {
        const position = this.getPositionFromMouseEvent(ev);
        this.onMove(position);
    };

    private getPositionFromTouchEvent(ev: TouchEvent) {
        return {
            x: ev.targetTouches[0].clientX,
            y: ev.targetTouches[0].clientY
        } as Point;
    }

    private getPositionFromMouseEvent(ev: MouseEvent) {
        return { x: ev.clientX, y: ev.clientY } as Point;
    }

    private onTouchMove = (ev: TouchEvent) => {
        const position = this.getPositionFromTouchEvent(ev);
        this.onMove(position);
    };

    private onMove = (position: Point) => {
        if (!this.dragItem.isDragging) {
            this.wasDragging = true;
            this.startDrag();
            this.addGrabbingCursor();
            return;
        }
        this.dragItem.autoScroll(position);
        this.dragItem.setCurrentPointWithScrollOffset(position.x, position.y);
        this.dragItem.translateClonedElement();
        this.handleDragOverElement(position);
    };

    private hasElementDropRole(element: HTMLElement) {
        return element?.attributes.getNamedItem('role')?.value === 'drop';
    }

    private addDragItemClass(element: HTMLElement): void {
        if (!element) {
            return;
        }

        const isSame = this.dragItem.isElementTheSame(element);
        const isDrop = this.hasElementDropRole(element);
        const dragOverClassName = getDragOverClassName(!isSame && isDrop).dragOverItem;

        element.classList.add(dragOverClassName);
    }

    private removeDragItemClass(element: HTMLElement | null) {
        if (!element) {
            return;
        }
        const isSame = this.dragItem.isElementTheSame(element);
        const isDrop = this.hasElementDropRole(element);
        const dragOverClassName = getDragOverClassName(!isSame && isDrop).dragOverItem;
        element.classList.remove(dragOverClassName);
    }

    private handleDragOverElement(position: Point) {
        const elementFromPoint = (document.elementFromPoint(position.x, position.y) as HTMLElement)?.closest(
            this.dragItem.getElementTagName()
        ) as HTMLElement;
        if (!this.dragItem.isDragOverElementTheSame(elementFromPoint)) {
            this.removeDragItemClass(this.dragItem.getDragOverElement());
            this.dragItem.setDragOverElement(elementFromPoint);
            this.addDragItemClass(this.dragItem.getDragOverElement());
        }
    }

    private onPointerUp = (ev: MouseEvent) => {
        ev.preventDefault();
        ev.stopPropagation();
        this.removeEventHandlers();
        this.onDrop();
    };

    private onTouchEnd = (ev: TouchEvent) => {
        this.rmoveTouchEventHandlers();
        this.onDrop();
    };

    private onDrop() {
        if (this.dragItem && this.dragItem.isDragging) {
            const dragOverElement = this.dragItem.getDragOverElement();
            const isSame = this.dragItem.isElementTheSame(dragOverElement);
            const isDrop = this.hasElementDropRole(dragOverElement);
            if (isDrop && !isSame) {
                this.props.onDrop(this.dragItem.getHtmlElement(), this.dragItem.getDragOverElement());
            }
        }
        this.clearDragItem();
    }

    private clearDragItem() {
        this.removeGrabbingCursor();
        if (this.dragItem.isDragging) {
            this.removeDragItemClass(this.dragItem.getDragOverElement());
            this.dragItem.clear();
        }
    }

    private startDrag() {
        this.dragItem.startDrag();
    }
}
