import { MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from '@fluentui/react';
import React, { FC, useState } from 'react';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { Types } from '../../config/Types';
import { IResponseError } from '../../interfaces/IResponseError';
import { ITokenObject } from '../../interfaces/ITokenObject';
import { Container } from '../../services/Container';
import { UserService } from '../../services/UserService';
import { ErrorHelper } from '../../utils/ErrorHelper';
import { getClassNames } from './resetpassword.style';

export interface IResetPassword {
    onGoToLoginPage: () => void;
}

export const ResetPassword: FC<IResetPassword> = ({ onGoToLoginPage }) => {
    const classNames = getClassNames();

    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [msg, setMsg] = useState('');

    const [tokenObject, setTokenObject] = useState<ITokenObject>({ token: '', newPassword: '', repeatNewPassword: '' });

    const goToLoginPage = () => {
        onGoToLoginPage();
    };

    const requestResetPassword = async () => {
        try {
            const userService = Container.get(Types.userService) as UserService;
            await userService.requestResetPassword(email);
            setMsg(
                'Bitte prüfe dein Emailpostfach, eine Email mit einem Reset-Token wurde an dich erfolgreich gesendet. Bitte gebe den Token hier ein und setze dein Passwort zurück.'
            );
        } catch (e) {
            setError(ErrorHelper.getMessage(e as IResponseError));
        }
    };

    const checkTokenObject = () => {
        if (!tokenObject.token) {
            throw new Error('Reset-Token wurde nicht gefunden');
        }

        if (!tokenObject.newPassword) {
            throw new Error('Neues Passwort darf nicht leer sein');
        }

        if (!tokenObject.repeatNewPassword) {
            throw new Error('Wiederholtes Passwort darf nicht leer sein');
        }

        if (tokenObject.newPassword !== tokenObject.repeatNewPassword) {
            throw new Error('Wiederholtes passwort stimmt nicht mit dem neuen Passwort überein');
        }
    };

    const resetPassword = async () => {
        try {
            checkTokenObject();
            const userService = Container.get(Types.userService) as UserService;
            await userService.resetPassword(tokenObject);
            goToLoginPage();
        } catch (e) {
            setError(ErrorHelper.getMessage(e as IResponseError));
        }
    };

    const onResetPassword = async (ev: React.FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        setError('');
        if (msg) {
            resetPassword();
            return;
        }
        requestResetPassword();
    };

    const onEmailChanged = (ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setEmail(newValue as string);
    };

    const onTokenObjectChanged = (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, v?: string) => {
        setTokenObject({ ...tokenObject, [e.currentTarget.name]: v || '' });
    };

    return (
        <>
            <Header>
                <h3>myGarage</h3>
            </Header>
            <div className={classNames.content}>
                <form className={classNames.form} onSubmit={onResetPassword}>
                    <h3>Reset password</h3>
                    {!msg && (
                        <>
                            <TextField
                                name="email"
                                placeholder="Deine email"
                                type="email"
                                required={true}
                                className={classNames.emailField}
                                value={email}
                                onChange={onEmailChanged}
                            />
                            <Stack horizontal={true} horizontalAlign="end">
                                <PrimaryButton type="submit">Reset password</PrimaryButton>
                            </Stack>
                        </>
                    )}
                    {msg && (
                        <>
                            <MessageBar className={classNames.successAlert} messageBarType={MessageBarType.success}>
                                {msg}
                            </MessageBar>
                            <TextField
                                name="token"
                                placeholder="Reset token aus der Email"
                                value={tokenObject.token}
                                onChange={(e, v) => onTokenObjectChanged(e, v)}
                            />
                            <TextField
                                name="newPassword"
                                type="password"
                                placeholder="Ein neuer Passwort"
                                value={tokenObject.newPassword}
                                onChange={(e, v) => onTokenObjectChanged(e, v)}
                            />
                            <TextField
                                name="repeatNewPassword"
                                type="password"
                                placeholder="Wiederhole das neue password"
                                value={tokenObject.repeatNewPassword}
                                onChange={(e, v) => onTokenObjectChanged(e, v)}
                            />
                            <Stack horizontal={true} horizontalAlign="end">
                                <PrimaryButton type="submit">Reset password</PrimaryButton>
                            </Stack>
                        </>
                    )}
                    {error && (
                        <MessageBar className={classNames.errorAlert} messageBarType={MessageBarType.error}>
                            {error}
                        </MessageBar>
                    )}
                    <div className={classNames.linkPanel}>
                        <a href="#!" className={classNames.loginLink} onClick={goToLoginPage}>
                            Einloggen
                        </a>
                    </div>
                </form>
            </div>
            <Footer />
        </>
    );
};
