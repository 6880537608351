import { Spinner } from '@fluentui/react';
import React from 'react';
import { getClassNames } from './Loading.style';

export const Loading: React.FC<{ className?: string }> = ({ className }) => {
    const classNames = getClassNames();
    return (
        <div className={[classNames.container, className].filter(Boolean).join(' ')}>
            <Spinner />
        </div>
    );
};
