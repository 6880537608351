import { ICategory } from '../interfaces/ICategory';
import $axios from './Client';

export class CategoryClient {
    public constructor(private axios = $axios) {}

    public async addCategory(category: ICategory) {
        const response = await this.axios.post<ICategory>('/category/create', category);
        return response.data;
    }

    public async getToolCategories() {
        const response = await this.axios.get<ICategory[]>('/category/all');
        return response.data;
    }
}
