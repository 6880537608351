import * as React from 'react';
import { Authentication } from '../components/Authentication';

export interface IAuthenticatedProps {
    server?: string;
}

export const requireAuth = <TOriginalProps,>(Component: React.ComponentType<TOriginalProps>) => {
    return class Authenticated extends React.Component<TOriginalProps & IAuthenticatedProps> {
        public render(): JSX.Element {
            return <Authentication render={this.renderHoc} />;
        }

        private readonly renderHoc = () => {
            return <Component {...this.props} />;
        };
    };
};
