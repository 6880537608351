import { FC, useState } from 'react';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { RegisterForm } from '../../components/RegisterForm';
import { Types } from '../../config/Types';
import { IRegisterUser } from '../../interfaces/IRegisterUser';
import { IResponseError } from '../../interfaces/IResponseError';
import { Container } from '../../services/Container';
import { UserService } from '../../services/UserService';
import { ErrorHelper } from '../../utils/ErrorHelper';
import { getClassNames } from './register.styles';

export interface IRegister {
    onLogin(): void;
}

export const Register: FC<IRegister> = ({ onLogin }) => {
    const classNames = getClassNames();
    const [error, setError] = useState('');
    const onRegister = async (user: IRegisterUser) => {
        try {
            const userService = Container.get(Types.userService) as UserService;
            await userService.register(user);
        } catch (e) {
            setError(ErrorHelper.getMessage(e as IResponseError));
        }
    };

    return (
        <>
            <Header>
                <h3>myGarage</h3>
            </Header>
            <div className={classNames.container}>
                <RegisterForm error={error} onSwitchToLogin={onLogin} onRegister={onRegister} />
            </div>
            <Footer />
        </>
    );
};
