import { IDraggableContext } from './IDraggableContext';

export class DummyDraggableContext implements IDraggableContext {
    getInfo(): string {
        return 'DummyDraggableContext';
    }
    public get wasDragging() {
        return false;
    }

    public set wasDragging(value: boolean) {
        // Not needed
    }

    public setItemHtmlElement(element: HTMLElement) {
        // Empty
    }
    public onMouseDown(ev: MouseEvent) {
        // Empty
    }
    public onTouchStart(ev: TouchEvent) {
        // Empty
    }
}
