import { Spinner } from '@fluentui/react';
import { createPortal } from 'react-dom';
import { getClassNames } from './waiting.style';

export const Waiting: React.FC<{ isLoading: boolean }> = ({ isLoading }) => {
    const classNames = getClassNames();
    return isLoading
        ? createPortal(
              <div className={classNames.container}>
                  <Spinner />;
              </div>,
              document.body
          )
        : null;
};
