import { getTheme, mergeStyleSets } from '@fluentui/react';

export const getClassNames = (theme = getTheme()) =>
    mergeStyleSets({
        container: [
            'settings-container',
            {
                height: 'calc(100dvh - 120px)',
                display: 'flex',
                gap: 10
            }
        ],
        listContainer: [
            'list-view-container',
            {
                position: 'relative',
                overflowX: 'hidden',
                flex: '1 1 auto'
            }
        ],
        treeViewContainer: [
            'settings-treeview-container',
            {
                flex: '0 0 250px',
                minWidth: 0,
                display: 'flex',
                flexDirection: 'column'
            }
        ],
        treeViewHeader: [
            'settings-treeview-header',
            {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0, 5, 5, 5',
                height: 42,
                boxSizing: 'border-box',
                borderBottom: `1px solid ${theme.palette.themePrimary}`,
                textAlign: 'left'
            }
        ],
        toolsContainer: [
            'settings-tools-container',
            {
                flex: '1 1 auto'
            }
        ],
        toolsHeader: [
            'settings-tools-header',
            {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: 5,
                height: 42,
                boxSizing: 'border-box',
                borderBottom: `1px solid ${theme.palette.themePrimary}`,
                textAlign: 'left'
            }
        ],
        toolListScrollableContainer: [
            'tool-list-scrollable-container',
            {
                height: 'calc(100% - 42px)',
                overflowY: 'auto'
            }
        ],
        noTools: [
            'settings-no-tools',
            {
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }
        ]
    });
