import { getTheme, mergeStyleSets } from '@fluentui/style-utilities';

export const getClassNames = (theme = getTheme()) =>
    mergeStyleSets({
        content: [
            'content',
            {
                display: 'flex',
                marginLeft: 15,
                marginRight: 15,
                justifyContent: 'center',
                flex: '1 1 auto',
                overflow: 'hidden'
            }
        ],
        form: [
            'form',
            {
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 520,
                minWidth: 270,
                margin: '15px auto',
                '& > *:not(:last-child)': {
                    marginBottom: 10
                }
            }
        ],
        emailField: [
            'email-field',
            {
                marginBottom: 10
            }
        ],
        linkPanel: [
            'link-panel',
            {
                textAlign: 'left',
                marginTop: 10
            }
        ],
        loginLink: [
            'login-link',
            {
                textDecoration: 'none',
                color: theme.palette.themePrimary
            }
        ],
        errorAlert: [
            'error-alert',
            {
                marginTop: 5,
                marginBottom: 5
            }
        ],
        successAlert: [
            'success-alert',
            {
                marginTop: 5,
                marginBottom: 5
            }
        ]
    });
