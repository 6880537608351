import { FontIcon, MessageBar, MessageBarType, Text, TextField } from '@fluentui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Utils } from '../../utils/Utils';
import { SidePanel } from '../SidePanel';
import { AddBagFormStyle } from './Style';
import { createNewAddStorageModel } from './addstorage.factory';
import { IAddStorageModel } from './addstorage.model';

export interface IAddBagFormProps {
    bagToUpdate: IAddStorageModel;
    error: Record<string, string>;
    showDialog: boolean;
    onClose(): void;
    onAdd(data: FormData): Promise<boolean>;
    onUpdate(data: FormData): Promise<boolean>;
}

export const AddBagForm: React.FC<IAddBagFormProps> = ({
    bagToUpdate,
    error,
    showDialog,
    onAdd,
    onUpdate,
    onClose
}) => {
    const form = useRef<HTMLFormElement>(null);
    const inputFile = useRef<HTMLInputElement>(null);
    const [storage, setStorage] = useState<IAddStorageModel>(bagToUpdate);
    const imageFile = useRef<File | null>(null);

    const onUploadPicture = () => {
        inputFile.current?.click();
    };

    const readImage = (file: File) => {
        imageFile.current = file;
        const fileReader = new FileReader();
        fileReader.onload = () => {
            setStorage({ ...storage, image: fileReader.result as string });
        };
        fileReader.readAsDataURL(file);
    };

    const getFormDataFromStorage = () => {
        const formData = new FormData();
        if (storage.id !== -1) {
            formData.append('id', String(storage.id));
        }
        formData.append('name', storage.name);
        formData.append('description', storage.description);
        if (storage.parentId && storage.parentId !== -1) {
            formData.append('parentId', String(storage.parentId));
        }
        formData.append('ownerId', String(storage.ownerId));
        if (imageFile.current) {
            formData.append('file', imageFile.current);
        } else {
            formData.append('image', storage.image);
        }
        return formData;
    };

    const resetForm = () => {
        imageFile.current = null;
        setStorage({
            ...storage,
            name: '',
            description: '',
            image: ''
        });
        if (form.current) {
            form.current.reset();
        }
    };

    const saveStorage = async () => {
        const data = getFormDataFromStorage();
        const result = storage.id > -1 ? await onUpdate(data) : await onAdd(data);
        if (result) {
            closeDialog();
        }
    };

    const closeDialog = () => {
        resetForm();
        onClose();
    };

    const onChange = (propertyName: string, newValue?: unknown) => {
        setStorage({
            ...storage,
            [propertyName]: newValue
        });
    };

    useEffect(() => {
        setStorage(bagToUpdate || createNewAddStorageModel(-1, -1));
    }, [bagToUpdate]);

    return (
        <SidePanel
            onDismiss={(ev) => {
                if (ev?.currentTarget && (ev.currentTarget as HTMLElement)?.tagName === 'BODY') {
                    return;
                }
                closeDialog();
            }}
            isOpen={showDialog}
            onOk={saveStorage}
            onCancel={async () => closeDialog()}
            headerText={bagToUpdate.parentId === -1 ? 'Neue Garage anlegen' : 'Neues Fach/Regal anlegen'}
        >
            <form ref={form} onSubmit={(event) => event.preventDefault()} className={AddBagFormStyle.container}>
                <div className={AddBagFormStyle.leftPanel}>
                    <div className={AddBagFormStyle.pictureContainer} onClick={onUploadPicture}>
                        {!storage.image && <FontIcon iconName="EditPhoto" />}
                        {storage.image && (
                            <img
                                className={AddBagFormStyle.image}
                                src={
                                    imageFile.current
                                        ? storage.image
                                        : `${Utils.getImage200Url()}${storage.image}` || ''
                                }
                                alt="Storage to add"
                            />
                        )}
                        <Text variant="small" className={AddBagFormStyle.pictureLabel}>
                            Photo...
                        </Text>
                        <input
                            ref={inputFile}
                            className={AddBagFormStyle.inputFile}
                            id="image"
                            name="image"
                            type="file"
                            accept="image/png, image/jpeg"
                            placeholder="Select a file"
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length) {
                                    readImage(e.target.files[0]);
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={AddBagFormStyle.rightPanel}>
                    {error.global && <MessageBar messageBarType={MessageBarType.error}>{error.global}</MessageBar>}
                    <TextField
                        name="name"
                        label={bagToUpdate.parentId === -1 ? 'Name für Garage' : 'Fach oder Regalname'}
                        value={storage.name}
                        onChange={(ev, newValue) => onChange('name', newValue)}
                    />
                    {error['name'] && <MessageBar messageBarType={MessageBarType.error}>{error['name']}</MessageBar>}
                </div>
            </form>
        </SidePanel>
    );
};
