import { DefaultButton, getTheme, Panel, PanelType, PrimaryButton } from '@fluentui/react';
import { ReactNode } from 'react';
import { getClassNames } from './side.panel.style';

export interface ISidePanel {
    headerText: string;
    isOpen: boolean;
    onDismiss: (ev?: React.SyntheticEvent<HTMLElement, Event> | KeyboardEvent | undefined) => void;
    children?: ReactNode;
    onOk?(): Promise<void>;
    onCancel?(): Promise<void>;
}

export const SidePanel: React.FC<ISidePanel> = ({ headerText, isOpen, onDismiss, children, onOk, onCancel }) => {
    const theme = getTheme();
    const classNames = getClassNames(theme);

    const renderFooter = () => {
        return (
            <div className={classNames.footer}>
                {onCancel && <DefaultButton onClick={onCancel}>Abbrechen</DefaultButton>}
                {onOk && (
                    <PrimaryButton
                        styles={{
                            root: { borderColor: theme.palette.white },
                            rootHovered: { borderColor: theme.palette.white }
                        }}
                        onClick={onOk}
                    >
                        Ja
                    </PrimaryButton>
                )}
            </div>
        );
    };

    return (
        <Panel
            isOpen={isOpen}
            onDismiss={onDismiss}
            type={PanelType.large}
            customWidth={undefined}
            closeButtonAriaLabel="Close"
            headerText={headerText}
            onRenderFooter={renderFooter}
            styles={{
                root: { marginTop: 'env(safe-area-inset-top)' },
                commands: { backgroundColor: theme.palette.themePrimary },
                header: { paddingBottom: 15 },
                headerText: { color: theme.palette.white },
                closeButton: { color: theme.palette.white },
                content: { paddingTop: 24 }
            }}
        >
            {children}
        </Panel>
    );
};
