import { mergeStyleSets } from '@fluentui/react';

export const getClassNames = function () {
    return mergeStyleSets({
        container: [
            'tool-details-container',
            {
                position: 'relative',
                textAlign: 'left',
                paddingLeft: 15,
                paddingRight: 15
            }
        ],
        closeBtn: [
            'tool-details-close',
            {
                position: 'absolute',
                right: 10,
                top: 10
            }
        ],
        parentRow: [
            'tool-details-parent-row',
            {
                display: 'flex',
                marginTop: 15,
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }
        ],
        parentImageContainer: [
            'tool-details-parent-image-container',
            {
                width: 250,
                height: 170,
                border: '1px solid silver',
                borderRadius: 5,
                padding: 5
            }
        ],
        parentImage: [
            'tool-details-parent-image',
            {
                width: 250,
                height: 170,
                objectFit: 'cover'
            }
        ],
        image: [
            'tool-details-image',
            {
                position: 'absolute',
                width: 'calc(100% - 10px)',
                height: 'calc(100% - 10px)',
                objectFit: 'cover',
                objectPosition: 'center',
                top: 5,
                left: 5
            }
        ],
        parentDetailsContainer: [
            'tools-details-parent-details',
            {
                display: 'flex',
                flexGrow: 1,
                flexDirection: 'column',
                marginLeft: 15
            }
        ],
        imageContainer: [
            'tools-details-image-container',
            {
                position: 'relative',
                width: '100%',
                height: 0,
                padding: 5,
                paddingTop: '56.25%',
                border: '1px solid silver',
                borderRadius: 5,
                boxSizing: 'border-box'
            }
        ],
        detailsRow: [
            'tools-details-details-container',
            {
                display: 'flex',
                flexDirection: 'column',
                marginTop: 15
            }
        ],
        toolDetails: [
            'tools-details-tool-details',
            {
                marginLeft: 15,
                display: 'flex',
                flexDirection: 'column'
            }
        ],
        breadCrumbs: [
            'tools-details-bread-crumbs',
            {
                margin: '10px 0'
            }
        ]
    });
};
