import React from 'react';
import { Utils } from '../../utils/Utils';
import { SidePanel } from '../SidePanel';
import { getClassNames } from './confirm.style';

interface IConfirmState {
    isOpen: boolean;
    headerText: string;
    bodyText: string;
    imageSrc: string;
    onOk?: () => Promise<void>;
    onCancel?: () => Promise<void>;
}

export class Confirm extends React.PureComponent<object, IConfirmState> {
    private classNames = getClassNames();

    public constructor(props: object) {
        super(props);
        this.state = this.getDefaultOptions();
    }

    public render() {
        const { isOpen, headerText, bodyText, imageSrc, onOk, onCancel } = this.state;
        return (
            <SidePanel
                headerText={headerText}
                isOpen={isOpen}
                onDismiss={this.onDismiss}
                onOk={onOk ? this.onOkCallback : undefined}
                onCancel={onCancel ? this.onCancelCallback : undefined}
            >
                {bodyText}
                {imageSrc && (
                    <div className={this.classNames.imageContainer}>
                        <img
                            className={this.classNames.image}
                            alt="Parent"
                            src={`${Utils.getImage300Url()}${imageSrc}`}
                        />
                    </div>
                )}
            </SidePanel>
        );
    }

    public areYouSure(options: {
        headerText: string;
        bodyText: string;
        imageSrc: string;
        onOk: () => Promise<void>;
        onCancel?: () => Promise<void>;
    }) {
        this.setState({ isOpen: true, ...options });
    }

    private onOkCallback = async () => {
        const { onOk } = this.state;
        this.onDismiss();
        if (onOk) {
            await onOk();
        }
    };

    private onCancelCallback = async () => {
        const { onCancel } = this.state;
        this.onDismiss();
        if (onCancel) {
            await onCancel();
        }
    };

    private onDismiss = () => {
        this.setState({ ...this.getDefaultOptions() });
    };

    private getDefaultOptions() {
        return {
            isOpen: false,
            headerText: '',
            bodyText: '',
            imageSrc: '',
            onOk: undefined,
            onCancel: undefined
        };
    }
}
