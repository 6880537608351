import { ComboBox, FontIcon, IComboBoxOption, MessageBar, MessageBarType, Text, TextField } from '@fluentui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Types } from '../../config/Types';
import { ICategory } from '../../interfaces/ICategory';
import { Container } from '../../services/Container';
import { ToolsService } from '../../services/ToolsService';
import { Utils } from '../../utils/Utils';
import { SidePanel } from '../SidePanel';
import { AddToolFormStyle } from './Style';
import { createNewAddToolModel } from './addtool.factory';
import { IAddToolModel } from './addtool.model';

export interface IAddToolForm {
    error: Record<string, string>;
    parentId: number;
    showForm: boolean;
    toolToUpdate?: IAddToolModel;
    onClose: () => void;
    onSaveTool: (data: FormData) => Promise<boolean>;
    onUpdateTool: (data: FormData) => Promise<boolean>;
}

export const AddToolForm: React.FC<IAddToolForm> = ({
    toolToUpdate,
    parentId,
    showForm,
    onClose,
    onSaveTool,
    onUpdateTool,
    error
}) => {
    const form = useRef<HTMLFormElement>(null);
    const inputFile = useRef<HTMLInputElement>(null);
    const [tool, setTool] = useState<IAddToolModel>(toolToUpdate || createNewAddToolModel(parentId));
    const [categories, setCategories] = useState<IComboBoxOption[]>([]);
    const imageFile = useRef<File | null>(null);

    const toolService = Container.get<ToolsService>(Types.toolsService);

    const onUploadPicture = () => {
        inputFile.current?.click();
    };

    const readImage = (file: File) => {
        imageFile.current = file;
        const fileReader = new FileReader();
        fileReader.onload = () => {
            setTool({ ...tool, image: fileReader.result as string });
        };
        fileReader.readAsDataURL(file);
    };

    const getFormDataOfNewTool = () => {
        const formData = new FormData();
        if (tool.id !== -1) {
            formData.append('id', String(tool.id));
        }
        formData.append('name', tool.name);
        formData.append('description', tool.description);
        if (tool.storageId !== -1) {
            formData.append('storageId', String(tool.storageId));
        }
        tool.categories.forEach((category) => {
            formData.append('categoryIds[]', String(category));
        });
        formData.append('file', imageFile.current || '');
        return formData;
    };

    const onSave = async () => {
        const data = getFormDataOfNewTool();
        const result = tool.id > -1 ? await onUpdateTool(data) : await onSaveTool(data);
        if (result) {
            closeDialog();
            return;
        }
    };

    const closeDialog = () => {
        onClose();
        resetForm();
    };

    const resetForm = () => {
        imageFile.current = null;
        if (form.current) {
            form.current.reset();
        }
    };

    const categoryToOption = (categroy: ICategory) => ({
        key: categroy.id,
        text: categroy.name
    });

    const onEditChange = (property: string, value: unknown) => {
        setTool((prevTool) => {
            return {
                ...prevTool,
                [property]: value
            };
        });
    };

    useEffect(() => {
        if (showForm) {
            toolService.getToolCategories().then((categories) => setCategories(categories.map(categoryToOption)));
        }
    }, [toolService, showForm]);

    useEffect(() => {
        setTool(toolToUpdate || createNewAddToolModel(parentId));
    }, [toolToUpdate, parentId]);

    return (
        <SidePanel
            isOpen={showForm}
            onDismiss={(ev) => {
                if (ev?.currentTarget && (ev.currentTarget as HTMLElement)?.tagName === 'BODY') {
                    return;
                }
                closeDialog();
            }}
            onOk={onSave}
            onCancel={async () => closeDialog()}
            headerText="Add tool"
        >
            <form ref={form} onSubmit={() => false} className={AddToolFormStyle.container}>
                <div className={AddToolFormStyle.leftPanel}>
                    <div className={AddToolFormStyle.pictureContainer} onClick={onUploadPicture}>
                        {!tool.image && <FontIcon iconName="EditPhoto" />}
                        {tool.image && (
                            <img
                                className={AddToolFormStyle.image}
                                src={imageFile.current ? tool.image : `${Utils.getImage200Url()}${tool.image}` || ''}
                                alt="Tool to add"
                            />
                        )}
                        <Text variant="small" className={AddToolFormStyle.pictureLabel}>
                            Photo...
                        </Text>
                        <input
                            placeholder="Add an image for this tool"
                            ref={inputFile}
                            className={AddToolFormStyle.inputFile}
                            id="image"
                            name="image"
                            type="file"
                            accept="image/png, image/jpeg"
                            onChange={(e) => {
                                if (e.target.files && e.target.files.length) {
                                    readImage(e.target.files[0]);
                                }
                            }}
                        />
                    </div>
                </div>
                <div className={AddToolFormStyle.rightPanel}>
                    {error.global && <MessageBar messageBarType={MessageBarType.error}>{error.global}</MessageBar>}
                    <TextField label="Tool name" value={tool.name} onChange={(e, v) => onEditChange('name', v || '')} />
                    {error['name'] && <MessageBar messageBarType={MessageBarType.error}>{error['name']}</MessageBar>}
                    <TextField
                        label="Description"
                        value={tool.description}
                        onChange={(e, v) => onEditChange('description', v || '')}
                    />
                    <ComboBox
                        label="Kategorie"
                        selectedKey={tool.categories}
                        multiSelect={true}
                        options={categories}
                        allowFreeform={true}
                        onChange={async (e, option, index, v) => {
                            if (!option && v) {
                                const newCategory = {
                                    name: v,
                                    description: v
                                } as ICategory;
                                const category = await toolService.createCategory(newCategory);
                                option = categoryToOption(category) as IComboBoxOption;
                                option.selected = true;
                                setCategories((categories) => [...categories, option as IComboBoxOption]);
                            }
                            if (option) {
                                const currentOption = option;
                                setTool((prevTool) => {
                                    return currentOption.selected
                                        ? {
                                              ...prevTool,
                                              categories: [...prevTool.categories, currentOption.key as number]
                                          }
                                        : {
                                              ...prevTool,
                                              categories: prevTool.categories.filter(
                                                  (category) => category !== currentOption.key
                                              )
                                          };
                                });
                                return;
                            }
                        }}
                    />
                    {error['categoryIds'] && (
                        <MessageBar messageBarType={MessageBarType.error}>{error['categoryIds']}</MessageBar>
                    )}
                </div>
            </form>
        </SidePanel>
    );
};
