import { ITool } from '../interfaces/ITool';
import $axios from './Client';

export class ToolsClient {
    public constructor(private axios = $axios) {}

    public async getAllTools() {
        const response = await this.axios.get<ITool[]>('/tool/all');
        return response.data;
    }

    public async addTool(data: FormData) {
        const response = await this.axios.post<ITool>('/tool/create', data);
        return response.data;
    }

    public async updateTool(data: FormData) {
        const response = await this.axios.post<ITool>('/tool/update', data);
        return response.data;
    }

    public async updateParent(id: number, newParentId: number) {
        const response = await this.axios.post<ITool>('/tool/update-parent', { id, parentId: newParentId });
        return response.data;
    }

    public async removeTool(item: ITool) {
        const response = await this.axios.delete(`/tool/remove/${item.id}`);
        return response.data;
    }

    public async fetchStorageTools(id: number) {
        const response = await this.axios.get<ITool[]>(`/tool/${id}`);
        return response.data;
    }

    public async findTools(query: string) {
        const response = await this.axios.get<ITool[]>(`/tool/find/${query}`);
        return response.data;
    }
}
