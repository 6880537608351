import { getTheme, mergeStyleSets } from '@fluentui/react';

export const getClassNames = (theme = getTheme()) =>
    mergeStyleSets({
        footer: [
            'side-panel-footer',
            {
                position: 'absolute',
                bottom: 0,
                display: 'flex',
                height: 60,
                width: '100%',
                padding: 20,
                alignItems: 'center',
                boxSizing: 'border-box',
                justifyContent: 'right',
                backgroundColor: theme.palette.themePrimary,
                '& > *:not(:first-child)': {
                    marginLeft: 10
                }
            }
        ]
    });
