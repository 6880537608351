import { IEventHandler } from '../interfaces/IEventHandler';

export class EventHandlers<EventArgType> {
    private _handlers: IEventHandler<EventArgType>[] = [];

    public add(handler: IEventHandler<EventArgType>) {
        if (this._handlers.indexOf(handler) > -1) {
            throw new Error('Handler already exists');
        }
        this._handlers.push(handler);
    }
    public remove(handler: IEventHandler<EventArgType>) {
        const index = this._handlers.indexOf(handler);
        if (index > -1) {
            this._handlers = [
                ...this._handlers.slice(0, index),
                ...this._handlers.slice(index + 1, this._handlers.length)
            ];
        }
    }
    public dispatch(eventArg: EventArgType) {
        this._handlers.forEach((handler) => handler(eventArg));
    }
}
