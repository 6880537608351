import { getTheme, IStackStyles, IStackTokens, mergeStyles, Stack, ThemeProvider } from '@fluentui/react';
import React, { useState } from 'react';
import { Main } from './pages/Main';
import { Settings } from './pages/Settings';
import { TestPage } from './pages/Test';
import { requireAuth } from './utils/requireAuth';

const theme = getTheme();
const stackTokens: IStackTokens = { childrenGap: 0 };
const stackStyles: Partial<IStackStyles> = {
    root: {
        width: '960px',
        margin: '0 auto',
        textAlign: 'center',
        color: '#605e5c'
    }
};

const containerClass = mergeStyles({
    position: 'relative',
    display: 'flex',
    height: 'calc(100dvh - env(safe-area-inset-bottom))',
    width: '100%'
});

const headerBackLevel = mergeStyles({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    height: 60,
    backgroundColor: theme.palette.themePrimary
});

const footerBackLevel = mergeStyles({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    height: 60,
    backgroundColor: theme.palette.themePrimary
});

export const App: React.FunctionComponent = () => {
    const [navigationPath, setNavigationPath] = useState('/main');

    const MainAuthenticated = requireAuth(Main);
    const SettingsAuthenticated = requireAuth(Settings);

    const isMain = () => navigationPath === '/main';
    const isSettings = () => navigationPath === '/settings';
    const isTest = () => navigationPath === '/test';

    return (
        <ThemeProvider className={containerClass}>
            <div className={headerBackLevel}></div>
            <div className={footerBackLevel}></div>
            <Stack
                horizontalAlign="stretch"
                verticalAlign="start"
                verticalFill
                styles={stackStyles}
                tokens={stackTokens}
            >
                {isMain() && <MainAuthenticated setNavigationPath={setNavigationPath} />}
                {isSettings() && <SettingsAuthenticated setNavigationPath={setNavigationPath} />}
                {isTest() && <TestPage />}
            </Stack>
        </ThemeProvider>
    );
};
