import { AxiosError } from 'axios';
import { IResponseError } from '../interfaces/IResponseError';

export class ErrorHelper {
    public static getMessage(e: IResponseError) {
        if (!e.response) {
            return e.message;
        }
        return e.response.data.message;
    }

    public static getErrorForControl(e: Error) {
        const axiosError = e as AxiosError<Record<string, string>>;
        if (axiosError && axiosError.response && axiosError.response.data) {
            if (!axiosError.response.data.message) {
                return axiosError.response.data;
            }
        }
        return {
            global: 'Storage could not be created successfully. ' + this.getMessage(e as IResponseError)
        };
    }
}
