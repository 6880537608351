import { DefaultButton, MessageBar, MessageBarType, PrimaryButton, Stack, TextField } from '@fluentui/react';
import React, { FC, useState } from 'react';
import { IRegisterUser } from '../../interfaces/IRegisterUser';
import { getClassNames } from './registerForm.styles';

export interface IRegisterForm {
    error?: string;
    onSwitchToLogin(): void;
    onRegister(user: IRegisterUser): void;
}

export const RegisterForm: FC<IRegisterForm> = ({ onSwitchToLogin: onLogin, onRegister, error }) => {
    const [user, setUser] = useState<IRegisterUser>({
        name: '',
        email: '',
        password: ''
    });
    const classNames = getClassNames();

    const onChanged = (property: string, newValue: string) => {
        setUser({
            ...user,
            [property]: newValue
        });
    };

    const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            onRegister(user);
        } catch (e) {
            console.error((e as Error).message);
        }
    };

    return (
        <form className={classNames.container} onSubmit={onSubmit}>
            <h2>Register</h2>
            <TextField
                name="name"
                value={user.name}
                onChange={(e, v) => onChanged(e.currentTarget.name, v || '')}
                className={classNames.formControl}
                placeholder="Name"
                required={true}
            />
            <TextField
                name="email"
                value={user.email}
                onChange={(e, v) => onChanged(e.currentTarget.name, v || '')}
                className={classNames.formControl}
                placeholder="E-Mail"
                autoComplete="username"
                required={true}
            />
            <TextField
                name="password"
                value={user.password}
                onChange={(e, v) => onChanged(e.currentTarget.name, v || '')}
                className={classNames.formControl}
                placeholder="Password"
                type="password"
                autoComplete="new-password"
                required={true}
            />
            {error && (
                <MessageBar className={classNames.formControl} messageBarType={MessageBarType.error}>
                    {error}
                </MessageBar>
            )}
            <Stack horizontal={true} horizontalAlign="end">
                <DefaultButton className={classNames.formButton} text="Login" onClick={onLogin} />
                <PrimaryButton type="submit" className={classNames.formButton} text="Register" />
            </Stack>
        </form>
    );
};
