import { CategoryClient } from '../api/CategroryClient';
import { StorageClient } from '../api/StorageClient';
import { ToolsClient } from '../api/ToolsClient';
import { ITreeViewNode } from '../components/TreeView/ITreeViewNode';
import { ICategory } from '../interfaces/ICategory';
import { IListItem } from '../interfaces/IListItem';
import { IStorage } from '../interfaces/IStorage';
import { ITool } from '../interfaces/ITool';
import { EventHandlers } from './EventHandler';

export class ToolsService {
    private _garageList: ITreeViewNode[] = [];

    public constructor(
        private _storageClient: StorageClient,
        private _toolsClient: ToolsClient,
        private _categoryClient: CategoryClient
    ) {}

    public async findTools(query: string) {
        return this._toolsClient.findTools(query);
    }

    public async getAllTools() {
        return this._toolsClient.getAllTools();
    }

    public async addTool(data: FormData) {
        return this._toolsClient.addTool(data);
    }

    public async updateTool(data: FormData) {
        return this._toolsClient.updateTool(data);
    }

    public async removeTool(item: ITool) {
        return this._toolsClient.removeTool(item);
    }

    public async getStorageTools(id: number) {
        const tools = await this._toolsClient.fetchStorageTools(id);
        return tools;
    }

    public get garageList() {
        return this._garageList;
    }

    public async getGarageStorages(garageId: number) {
        const storages = await this._storageClient.getGarageStorages(garageId);
        return storages.map((item) => {
            return {
                id: String(item.id),
                parentId: String(item.parentId),
                iconName: 'Storyboard',
                text: item.name,
                value: null,
                hasChildren: item.hasChildren,
                isExpanded: false,
                dataItem: item
            } as ITreeViewNode;
        });
    }

    public async loadItems(parentId: number): Promise<IListItem[]> {
        if (parentId < 0) {
            const garages = await this._storageClient.getGarages();
            return garages;
        }
        const storages = await this._storageClient.getGarageStorages(parentId);
        const tools = await this._toolsClient.fetchStorageTools(parentId);
        return [...storages, ...tools];
    }

    public async fetchGarageList() {
        const garages = await this._storageClient.getGarages();
        this._garageList = garages.map(
            (garage: IStorage) =>
                ({
                    id: String(garage.id),
                    parentId: '',
                    key: garage.id,
                    text: garage.name,
                    iconName: 'Storyboard',
                    value: null,
                    hasChildren: garage.hasChildren,
                    isExpanded: true,
                    dataItem: garage
                }) as ITreeViewNode
        );
        this.garageListChanged.dispatch({});
    }

    public async getToolCategories() {
        return this._categoryClient.getToolCategories();
    }

    public async createCategory(category: ICategory) {
        return this._categoryClient.addCategory(category);
    }

    public getGarageById(id: number) {
        return this._garageList.find((garage) => garage.id === String(id));
    }

    public getParents(parentId: number) {
        return this._storageClient.getParents(parentId);
    }

    public garageListChanged = new EventHandlers<Record<string, unknown>>();
}
