import { getTheme, mergeStyles } from '@fluentui/react';

export const getClonedItemClassNames = (theme = getTheme()) => ({
    draggableItem: mergeStyles({
        backgroundColor: theme.palette.neutralLight,
        pointerEvents: 'none',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
        willChange: 'transform'
    })
});

export const getDragOverClassNames = (theme = getTheme()) => ({
    dragOverItem: mergeStyles({
        backgroundColor: theme.palette.themeSecondary,
        '&:hover': {
            backgroundColor: theme.palette.themeSecondary
        }
    })
});

export const getDragOverClassName = (isDrop = false) => ({
    dragOverItem: mergeStyles({
        backgroundColor: isDrop ? getTheme().palette.themeSecondary : getTheme().palette.neutralLight,
        opacity: isDrop ? 1 : 0.5,
        '&:hover': {
            backgroundColor: isDrop ? getTheme().palette.themeSecondary : getTheme().palette.neutralLight,
            opacity: isDrop ? 1 : 0.5
        }
    })
});
