import { mergeStyleSets } from '@fluentui/react';

export const getClassNames = () =>
    mergeStyleSets({
        alertContainer: [
            'alert-container',
            {
                position: 'relative'
            }
        ],
        closeButton: [
            'alert-close-button',
            {
                position: 'absolute',
                right: 4,
                top: 1
            }
        ]
    });
