import { mergeStyleSets } from '@fluentui/react';

export const getClassNames = () =>
    mergeStyleSets({
        persona: [
            'persona',
            {
                '.ms-Persona-details': {
                    '@media(max-width: 540px)': {
                        display: 'none'
                    }
                },
                '@media(max-width: 390px)': {
                    ':nth-child(3)': { order: 2 }
                }
            }
        ],
        searchTextBox: [
            'search-text-box',
            {
                '@media(max-width: 390px)': {
                    ':nth-child(2)': { order: 3 },
                    width: '100%'
                }
            }
        ],
        profile: [
            'profile',
            {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }
        ],
        profileCallout: [
            'profile-callout',
            {
                width: 250,
                padding: 10
            }
        ],
        header: [
            'main-header',
            {
                '@media(max-width: 390px)': {
                    height: '100px !important',
                    flexWrap: 'wrap'
                }
            }
        ]
    });
