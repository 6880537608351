import { mergeStyles, mergeStyleSets } from '@fluentui/merge-styles';
import { getTheme } from '@fluentui/style-utilities';

const theme = getTheme();

export const AddToolFormStyle = {
    container: mergeStyles({
        display: 'flex',
        alignItems: 'flex-start',
        '@media(max-width: 639px)': {
            flexDirection: 'column',
            alignItems: 'stretch'
        }
    }),
    leftPanel: mergeStyles({
        display: 'block',
        padding: '15px'
    }),
    rightPanel: mergeStyles({
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column'
    }),
    inputFile: mergeStyles({
        display: 'none'
    }),
    image: mergeStyles({
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    }),
    ...mergeStyleSets({
        pictureContainer: {
            position: 'relative',
            marginLeft: 'auto',
            marginRight: 'auto',
            cursor: 'pointer',
            border: `1px solid ${theme.palette.neutralLight}`,
            borderRadius: '3px',
            padding: '5px',
            width: '75px',
            height: '75px',
            fontSize: '75px',
            lineHeight: '100%',
            transition: 'background .25s linear',
            '&:hover:after': {
                content: "''",
                position: 'absolute',
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
                background: theme.palette.blackTranslucent40,
                borderRadius: '3px'
            },
            '&:hover .pictureLabel': {
                opacity: 1
            },
            '& img': {
                fontSize: 14,
                lineHeight: 'initial',
                textAlign: 'right'
            }
        },
        pictureLabel: [
            'pictureLabel',
            {
                position: 'absolute',
                display: 'block',
                width: '100%',
                boxSizing: 'border-box',
                left: 0,
                bottom: 0,
                color: theme.palette.white,
                lineHeight: '100%',
                padding: '10px',
                fontWeight: '700',
                background: theme.palette.themePrimary,
                opacity: 0,
                transition: 'opacity .25s linear',
                zIndex: 2,
                borderBottomLeftRadius: '3px',
                borderBottomRightRadius: '3px'
            }
        ]
    })
};
