import { IStorage } from '../interfaces/IStorage';
import $axios from './Client';

export class StorageClient {
    public constructor(private axios = $axios) {}

    public async getGarages() {
        const response = await this.axios.get<IStorage[]>('/storage/garages');
        return response.data;
    }

    public async getParents(id: number) {
        const response = await this.axios.get<IStorage[]>(`storage/parents/${id}`);
        return response.data;
    }

    public async getGarageStorages(garageId: number) {
        const response = await this.axios.get<IStorage[]>(`/storage/${garageId}`);
        return response.data;
    }

    public async createStorage(storage: FormData) {
        const response = await this.axios.post<IStorage>('/storage/create', storage);
        return response.data;
    }

    public async updateStorage(storage: FormData) {
        const response = await this.axios.post<IStorage>('/storage/update', storage);
        return response.data;
    }

    public async updateParent(id: number, newParentId: number) {
        const response = await this.axios.post<IStorage>('/storage/update-parent', { id, parentId: newParentId });
        return response.data;
    }

    public async removeStorage(id: number) {
        const response = await this.axios.delete('/storage/remove', {
            data: { id }
        });
        return response.data;
    }
}
