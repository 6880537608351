import { mergeStyles } from '@fluentui/merge-styles';
import { getTheme } from '@fluentui/style-utilities';

const theme = getTheme();

export const footerStyle = {
    container: mergeStyles({
        position: 'relative',
        display: 'flex',
        flexShrink: 0,
        boxSizing: 'border-box',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme.palette.themePrimary,
        width: '100%',
        height: '60px',
        padding: 'auto 15px',
        color: theme.palette.white
    }),
    addButton: mergeStyles({
        position: 'absolute',
        left: '50%',
        transform: 'translateX(-50%)',
        bottom: '40%',
        borderRadius: '50%',
        outline: `solid 4px ${theme.palette.white}`,
        minWidth: '70px',
        minHeight: '70px',
        fontSize: '28px',
        fontWeight: 'normal'
    })
};
