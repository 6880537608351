import { Types } from '../../config/Types';
import { IStorage } from '../../interfaces/IStorage';
import { Container } from '../../services/Container';
import { UserService } from '../../services/UserService';
import { IAddStorageModel } from './addstorage.model';

export function createNewAddStorageModel(parentId = -1, ownerId = -1): IAddStorageModel {
    if (ownerId === -1) {
        const userService = Container.get<UserService>(Types.userService);
        ownerId = userService.getUserInfo().id;
    }
    return { id: -1, name: '', image: '', description: '', parentId, ownerId };
}

export function getAddStorageModelFromStorage(storage: IStorage): IAddStorageModel {
    return {
        id: storage.id,
        name: storage.name,
        description: storage.description,
        image: storage.image,
        parentId: storage.parentId || -1,
        ownerId: storage.ownerId
    };
}
