import { AuthClient } from '../api/AuthClient';
import { CategoryClient } from '../api/CategroryClient';
import { StorageClient } from '../api/StorageClient';
import { ToolsClient } from '../api/ToolsClient';
import { Container } from '../services/Container';
import { ToolsService } from '../services/ToolsService';
import { UserService } from '../services/UserService';
import { AuthStorage } from '../Storage/AuthStorage';
import { Types } from './Types';

(function configureContainer() {
    Container.add(Types.authClient, new AuthClient());
    Container.add(Types.userService, new UserService(Container.get(Types.authClient) as AuthClient));
    Container.add(Types.authStorage, new AuthStorage());
    Container.add(Types.storageClient, new StorageClient());
    Container.add(Types.toolsClient, new ToolsClient());
    Container.add(Types.categoryClient, new CategoryClient());
    Container.add(
        Types.toolsService,
        new ToolsService(
            Container.get(Types.storageClient),
            Container.get(Types.toolsClient),
            Container.get(Types.categoryClient)
        )
    );
})();
