import { IBreadCrumbItem } from '../../interfaces/IBreadCrumbItem';
import { getClassNames } from './Style';

export interface IBreadCrumbsProps {
    items: IBreadCrumbItem[];
    activeIndex: number;
    onItemClicked(index: number): void;
}

export const BreadCrumbs: React.FC<IBreadCrumbsProps> = ({ items, activeIndex, onItemClicked }) => {
    const classNames = getClassNames();
    return (
        <ul className={classNames.breadCrumbsContainer}>
            {items.map((item, index) => {
                const activeClass = index === activeIndex ? ' active' : '';
                return (
                    <li
                        key={item.id}
                        className={classNames.breadCrumbItem + activeClass}
                        onClick={() => onItemClicked(index)}
                    >
                        {item.name}
                    </li>
                );
            })}
        </ul>
    );
};
