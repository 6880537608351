import { getTheme, mergeStyleSets } from '@fluentui/react';

export const getClassNames = (theme = getTheme()) =>
    mergeStyleSets({
        container: [
            'loading-container',
            {
                position: 'fixed',
                top: 0,
                left: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
                opacity: 0.75,
                zIndex: 2000000,
                background: theme.palette.black,
                userSelect: 'none'
            }
        ]
    });
