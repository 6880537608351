export class AuthStorage {
    private static KEY = 'auth_storage_key';

    public get() {
        const item = localStorage.getItem(AuthStorage.KEY);
        if (!item) {
            return null;
        }
        return JSON.parse(item) as {
            accessToken: string;
            refreshToken: string;
        };
    }

    public set(tokens: { accessToken: string; refreshToken: string }) {
        localStorage.setItem(AuthStorage.KEY, JSON.stringify(tokens));
    }

    public clear() {
        localStorage.removeItem(AuthStorage.KEY);
    }
}
