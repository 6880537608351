import React, { ReactElement, useContext, useRef } from 'react';
import { DraggableContext } from '.';

export interface IDraggableItem {
    children?: React.ReactNode;
    isDrop?: boolean;
}

export const DraggableItem: React.FC<IDraggableItem> = ({ children, isDrop }) => {
    const ref = useRef<HTMLElement>();
    const manager = useContext(DraggableContext);

    const onMouseDown = (e: MouseEvent) => {
        if (manager.wasDragging) {
            return;
        }
        manager.onMouseDown(e);
    };

    const onPointerDown = (e: PointerEvent) => {
        manager.setItemHtmlElement(ref.current as HTMLElement);
    };

    const onClick = (e: MouseEvent) => {
        if (!manager.wasDragging) {
            (children as ReactElement)?.props?.onClick(e);
        } else {
            manager.wasDragging = false;
        }
    };

    const onTouchStart = (e: TouchEvent) => {
        manager.wasDragging = false;
    };

    const child = React.Children.only(children) as ReactElement;
    return React.cloneElement(child, {
        ref,
        onClick,
        onMouseDown,
        onPointerDown,
        onTouchStart,
        style: { userSelect: 'none', WebkitTouchCallout: 'none' },
        role: isDrop ? 'drop' : undefined
    });
};
