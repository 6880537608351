import { getTheme, mergeStyleSets } from '@fluentui/style-utilities';

export const getClassNames = (theme = getTheme()) =>
    mergeStyleSets({
        content: [
            'content',
            {
                display: 'flex',
                justifyContent: 'center',
                flex: '1 1 auto',
                overflow: 'hidden'
            }
        ]
    });
