import { getTheme, initializeIcons, mergeStyles } from '@fluentui/react';
import { createRoot } from 'react-dom/client';
import { App } from './App';
import './config/configureContainer';

// Inject some global styles
mergeStyles({
    ':global(html)': {
        position: 'fixed',
        margin: 0,
        padding: 0,
        overflow: 'hidden'
    },
    ':global(body)': {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 0,
        maxHeight: '100vh',
        width: '100%',
        padding:
            'env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)',
        overflow: 'hidden',
        background: getTheme().palette.themePrimary,
        boxSizing: 'border-box'
    }
});
initializeIcons();
const rootElement = document.getElementById('root') as HTMLElement;
const root = createRoot(rootElement);
root.render(<App />);
