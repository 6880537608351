import { getTheme, IconButton, Text } from '@fluentui/react';
import { AsyncImage } from 'loadable-image';
import React from 'react';
import { ITool } from '../../interfaces/ITool';
import { Utils } from '../../utils/Utils';
import { DraggableItem } from '../DragNDrop/DraggableItem';
import { DragTouchHandle } from '../DragNDrop/DragTouchHandle';
import { toolItemStyle } from './style';

export interface IToolItem {
    item: ITool;
    onClick(item: ITool): void;
    onRemove(item: ITool): void;
    onMoveOneLevelUp?(item: ITool): void;
    isRemoveBtnVisible: boolean;
    isMoveUpBtnVisible?: boolean;
}

export const ToolItem: React.FC<IToolItem> = ({
    item,
    onClick,
    onRemove,
    onMoveOneLevelUp,
    isRemoveBtnVisible,
    isMoveUpBtnVisible
}) => {
    const theme = getTheme();
    return (
        <DraggableItem>
            <li key={item.id} data-id={item.id} className={toolItemStyle.container} onClick={() => onClick(item)}>
                <div className={toolItemStyle.iconContainer}>
                    <AsyncImage
                        className={toolItemStyle.icon}
                        src={`${Utils.getImage200Url()}${item.image}`}
                        alt={item.name}
                    />
                </div>
                <div className={toolItemStyle.infoContainer}>
                    <DragTouchHandle>
                        <span className={toolItemStyle.nameContainer}>
                            <Text
                                variant="xLarge"
                                styles={{
                                    root: {
                                        width: '100%',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis'
                                    }
                                }}
                                color={theme.palette.neutralLighter}
                            >
                                {item.name}
                            </Text>
                        </span>
                    </DragTouchHandle>
                    <Text variant="mediumPlus">{item.description}</Text>
                    <Text variant="medium">Count: 1</Text>
                    <Text variant="small">Category: {item.categories.map((category) => category.name).join(',')}</Text>
                </div>
                <div className={toolItemStyle.actionButtonPanel}>
                    {isMoveUpBtnVisible && (
                        <IconButton
                            iconProps={{ iconName: 'PublishContent' }}
                            title="Verschiebe eine Ebene höher"
                            onClick={(e) => {
                                e.stopPropagation();
                                onMoveOneLevelUp && onMoveOneLevelUp(item);
                            }}
                        />
                    )}
                    {isRemoveBtnVisible && (
                        <IconButton
                            iconProps={{ iconName: 'Delete' }}
                            title="Das Werkzeug entfernen"
                            onClick={(e) => {
                                e.stopPropagation();
                                onRemove(item);
                            }}
                        />
                    )}
                </div>
            </li>
        </DraggableItem>
    );
};
