import { Spinner } from '@fluentui/react';
import { IListItem } from '../../interfaces/IListItem';
import { getWaitItemClassNames } from './Style';

export interface IWaitingItemProps {
    item: IListItem;
}
export const WaitingItem: React.FC<IWaitingItemProps> = ({ item }) => {
    const classNames = getWaitItemClassNames();
    return (
        <li className={classNames.container} key={item.id}>
            <Spinner />
        </li>
    );
};
