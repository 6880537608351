import { getTheme, Icon, IconButton, Text } from '@fluentui/react';
import React from 'react';
import { IStorage } from '../../interfaces/IStorage';
import { Utils } from '../../utils/Utils';
import { DraggableItem } from '../DragNDrop/DraggableItem';
import { DragTouchHandle } from '../DragNDrop/DragTouchHandle';
import { storageItemStyle } from './Style';

export interface IStorageItem {
    item: IStorage;
    onClick(item: IStorage): void;
    onRemove(item: IStorage): void;
    onEdit(item: IStorage): void;
    onMoveOneLevelUp?(item: IStorage): void;
    isRemoveBtnVisible: boolean;
    isEditBtnVisible: boolean;
    isMoveUpBtnVisible: boolean;
}

export const StorageItem: React.FC<IStorageItem> = ({
    item,
    onClick,
    onRemove,
    onEdit,
    onMoveOneLevelUp,
    isRemoveBtnVisible,
    isEditBtnVisible,
    isMoveUpBtnVisible
}) => {
    const theme = getTheme();
    return (
        <DraggableItem isDrop={Boolean(item.parentId)}>
            <li
                key={item.id}
                data-isstorage={true}
                data-id={item.id}
                className={storageItemStyle.container}
                onClick={() => onClick(item)}
            >
                <div className={storageItemStyle.iconContainer}>
                    <img
                        className={storageItemStyle.icon}
                        src={`${Utils.getImage200Url()}${item.image}`}
                        alt={item.name}
                    />
                </div>
                <div className={storageItemStyle.infoContainer}>
                    <DragTouchHandle>
                        <span className={storageItemStyle.nameContainer}>
                            <Text
                                variant="xLarge"
                                styles={{
                                    root: {
                                        width: '100%',
                                        overflow: 'hidden',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis'
                                    }
                                }}
                                color={theme.palette.neutralLighter}
                            >
                                {item.name}
                            </Text>
                        </span>
                    </DragTouchHandle>
                    <Text variant="mediumPlus">{item.description}</Text>
                </div>
                <div className={storageItemStyle.actionButtonPanel}>
                    {isMoveUpBtnVisible && (
                        <IconButton
                            iconProps={{ iconName: 'PublishContent' }}
                            title="Verschiebe eine Ebene höher"
                            onClick={(e) => {
                                e.stopPropagation();
                                onMoveOneLevelUp && onMoveOneLevelUp(item);
                            }}
                        />
                    )}
                    {isEditBtnVisible && (
                        <IconButton
                            iconProps={{ iconName: 'Edit' }}
                            title="Den Storage editieren"
                            onClick={(e) => {
                                e.stopPropagation();
                                onEdit(item);
                            }}
                        />
                    )}
                    {isRemoveBtnVisible && (
                        <IconButton
                            iconProps={{ iconName: 'Delete' }}
                            title="Das Werkzeug entfernen"
                            onClick={(e) => {
                                e.stopPropagation();
                                onRemove(item);
                            }}
                        />
                    )}
                </div>
                <div className={storageItemStyle.chevronRight}>
                    <Icon iconName="ChevronRight" />
                </div>
            </li>
        </DraggableItem>
    );
};
