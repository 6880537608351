import { FC, useState } from 'react';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { LoginForm } from '../../components/LoginForm';
import { Types } from '../../config/Types';
import { ILoginUser } from '../../interfaces/ILoginUser';
import { IResponseError } from '../../interfaces/IResponseError';
import { Container } from '../../services/Container';
import { UserService } from '../../services/UserService';
import { ErrorHelper } from '../../utils/ErrorHelper';
import { getClassNames } from './login.styles';

export interface ILogin {
    onRegister(): void;
    onPasswordReset(): void;
}

export const Login: FC<ILogin> = ({ onRegister, onPasswordReset }) => {
    const classNames = getClassNames();
    const [error, setError] = useState('');

    const onLogin = async (user: ILoginUser) => {
        setError('');
        try {
            const userService = Container.get(Types.userService) as UserService;
            await userService.login(user);
        } catch (e) {
            setError(ErrorHelper.getMessage(e as IResponseError));
        }
    };

    return (
        <>
            <Header>
                <h3>myGarage</h3>
            </Header>
            <div className={classNames.content}>
                <LoginForm
                    onSwitchToRegister={onRegister}
                    onLogin={onLogin}
                    error={error}
                    onSwitchToPasswordReset={onPasswordReset}
                />
            </div>
            <Footer />
        </>
    );
};
