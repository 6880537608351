import {
    ActionButton,
    Callout,
    FontWeights,
    getTheme,
    IconButton,
    ITextStyles,
    mergeStyles,
    Persona,
    PersonaSize,
    SearchBox,
    Text
} from '@fluentui/react';
import { FC, useEffect, useRef, useState } from 'react';
import { Footer } from '../../components/Footer';
import { Header } from '../../components/Header';
import { ToolDetails } from '../../components/ToolDetails';
import { ToolList } from '../../components/ToolList';
import { Types } from '../../config/Types';
import { ITool } from '../../interfaces/ITool';
import { Container } from '../../services/Container';
import { ToolsService } from '../../services/ToolsService';
import { UserService } from '../../services/UserService';
import { getClassNames } from './main.style';

const theme = getTheme();

const contentClass = mergeStyles({
    flex: '1 1 auto',
    overflow: 'hidden',
    overflowY: 'auto'
});

const boldStyle: Partial<ITextStyles> = {
    root: {
        fontWeight: FontWeights.semibold,
        color: theme.palette.white
    }
};

export interface IMainProps {
    setNavigationPath(path: string): void;
}

export const Main: FC<IMainProps> = ({ setNavigationPath }) => {
    const classNames = getClassNames();
    const [searchText, setSearchText] = useState('');
    const [isCalloutOpen, setIsCalloutOpen] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [selectedTool, setSelectedTool] = useState<ITool | undefined>(undefined);
    const [tools, setTools] = useState<ITool[]>([]);
    const containerDiv = useRef<HTMLDivElement>(null);
    const containerDivScrollPosition = useRef(0);
    const userService = Container.get<UserService>(Types.userService);

    const searchTimer = useRef<number>(0);

    const toolService = Container.get<ToolsService>(Types.toolsService);

    const handleCallout = () => {
        setIsCalloutOpen(!isCalloutOpen);
    };

    const onLogout = () => {
        setIsCalloutOpen(false);
        userService.logout();
    };

    const closeDetails = () => {
        setShowDetails(false);
    };

    const onItemClick = (item: ITool) => {
        if (containerDiv.current) {
            containerDivScrollPosition.current = containerDiv.current.scrollTop;
        }
        setSelectedTool(item);
        setShowDetails(true);
    };

    const onChange = (newValue: string) => {
        setSearchText(newValue);
        searchTools(newValue);
    };

    const searchTools = (query: string) => {
        window.clearTimeout(searchTimer.current);
        searchTimer.current = window.setTimeout(() => {
            if (showDetails) {
                setShowDetails(false);
            }
            if (query) {
                toolService.findTools(query).then((tools) => {
                    setTools(tools);
                });
                return;
            }
            toolService.getAllTools().then((tools) => setTools(tools));
        }, 300);
    };

    useEffect(() => {
        toolService.getAllTools().then((tools) => {
            setTools(tools);
        });
    }, [toolService]);

    useEffect(() => {
        if (containerDiv.current) {
            if (showDetails) {
                containerDiv.current.scrollTop = 0;
            } else {
                containerDiv.current.scrollTop = containerDivScrollPosition.current;
            }
        }
    }, [showDetails]);

    return (
        <>
            <Header className={classNames.header}>
                <Text variant="large" styles={boldStyle}>
                    myGarage
                </Text>
                <SearchBox
                    className={classNames.searchTextBox}
                    showIcon
                    placeholder="Suche nach ersatzteile"
                    onChange={(e, newValue) => onChange(newValue || '')}
                    value={searchText}
                />
                <div className={classNames.profile}>
                    <Persona
                        className={classNames.persona}
                        text={userService.getUserInfo().name}
                        styles={{ primaryText: { color: theme.palette.white } }}
                        size={PersonaSize.size32}
                    />
                    <IconButton
                        id="callout-button"
                        styles={{
                            root: {
                                color: theme.palette.white
                            },
                            rootHovered: {
                                color: theme.palette.themePrimary
                            },
                            icon: {
                                transform: isCalloutOpen ? 'rotate(180deg)' : '',
                                transition: 'transform .5s ease-out'
                            }
                        }}
                        iconProps={{ iconName: 'ChevronDownMed' }}
                        onClick={(e) => handleCallout()}
                    />
                    {isCalloutOpen && (
                        <Callout
                            className={classNames.profileCallout}
                            ariaLabelledBy="label-id"
                            ariaDescribedBy="description-id"
                            gapSpace={0}
                            target={'#callout-button'}
                            onDismiss={handleCallout}
                            setInitialFocus
                        >
                            <ActionButton iconProps={{ iconName: 'leave' }} onClick={onLogout}>
                                Logout
                            </ActionButton>
                        </Callout>
                    )}
                </div>
            </Header>
            <div ref={containerDiv} className={contentClass}>
                {!showDetails && <ToolList items={tools} onItemClick={onItemClick} />}
                {showDetails && <ToolDetails close={closeDetails} tool={selectedTool} />}
            </div>
            <Footer>
                <Text variant="large" styles={boldStyle}>
                    myGarage
                </Text>
                <div className="action-panel">
                    <IconButton
                        styles={{
                            root: {
                                color: theme.palette.white
                            },
                            rootHovered: {
                                color: theme.palette.themePrimary
                            }
                        }}
                        iconProps={{
                            iconName: 'Settings'
                        }}
                        onClick={() => setNavigationPath('/settings')}
                    />
                </div>
            </Footer>
        </>
    );
};
